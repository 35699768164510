import { getCookie, setCookie } from "app/utils/browser";
import { FaList } from "react-icons/fa";
import { IoGridSharp } from "react-icons/io5";
import { BsGrid3X3 } from "react-icons/bs";
import { CiGrid2V } from "react-icons/ci";

import React from "react";
interface Props {
  onThemeChange(keyword: string): void;
}

const GridChange = (props: Props) => {
  const { onThemeChange } = props;
  let activetheme = getCookie("theme");
  return (
    <div className="d-flex justify-content-end gap-3 mr-2 sticky top-20">
      <span
        onClick={() => {
          setCookie("theme", "default");
          onThemeChange("pro-grid-2");
        }}
        role="button"
        className="border p-2 mr-2"
        style={{
          background:
            activetheme === "default" || activetheme === "" ? "#28bcad" : "",
          color: activetheme === "default" || activetheme === "" ? "white" : "",
        }}
        title="default"
      >
        <FaList className="text-2xl" />
      </span>
      <span
        onClick={() => {
          setCookie("theme", "pro-list");
          onThemeChange("pro-list");
        }}
        role="button"
        className="border p-2 mr-2"
        style={{
          background: activetheme === "pro-list" ? "#28bcad" : "",
          color: activetheme === "pro-list" ? "white" : "",
        }}
        title="pro-list"
      >
        <BsGrid3X3 className="text-2xl" />
      </span>

      <span
        onClick={() => {
          setCookie("theme", "pro-grid-2");
          onThemeChange("pro-grid");
        }}
        className="border p-2 mr-2"
        role="button"
        style={{
          background: activetheme === "pro-grid-2" ? "#28bcad" : "",
          color: activetheme === "pro-grid-2" ? "white" : "",
        }}
        title="pro-grid"
      >
        <IoGridSharp className="text-2xl" />
      </span>
      <span
        onClick={() => {
          setCookie("theme", "new-grid");
          onThemeChange("new-grid");
        }}
        className="border p-2"
        role="button"
        style={{
          background: activetheme === "new-grid" ? "#28bcad" : "",
          color: activetheme === "new-grid" ? "white" : "",
        }}
        title="new-grid"
      >
        <CiGrid2V className="text-2xl" />
      </span>
    </div>
  );
};
export default GridChange;
