// https://api.whatsapp.com/send?phone=96555300444&text=*New Order : #0017*
// *(_Home Delivery_)*
//
// Crab Meat With Sweet Com Soup سلطعون اللحوم مع شوربة الذرة x 1
//
// Total Payable: *KWD 1.500*
//
//
// *_Address:_*
// KHODIYAR SOCIETY SHERI NO-1, KOTHARIYA ROAD
// 3456123455
//
// Please confirm via reply.

import { appendCurrency } from "app/utils/product";
export const BUSINESS_NAME = window.location.pathname.split("/").length >= 1 ? window.location.pathname.split("/")[1] : "a"
export const paymentgateway = ({
  orderId,
  totalAmount
}: any) => {
  const queryParams = new URLSearchParams({
    merchant_id: '1201',
    username: "test",
    password: "test",
    api_key: 'jtest123',
    order_id: orderId,
    total_price: totalAmount,
    CurrencyCode: "INR",
    CstMobile: '7990892778',
    success_url: `${window.location.origin}/${BUSINESS_NAME}/order-successful`,
    error_url: `${window.location.origin}/${BUSINESS_NAME}/order-failed`,
    test_mode: '1',
  });

  return fetch(`https://api.upayments.com/test-payment?${queryParams.toString()}`, {
    method: "POST",
  }).then((res) => res.json());

}
export const getOrderStatus = (orderId: string) => {
  // http://whatshop.loc/api/order/status/theitwebcare?OrderID=36
  return fetch(
    `/api/order/status/${BUSINESS_NAME}?OrderID=${orderId}`,
    {
      method: "GET",
    }
  ).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw Error;
    }
  });
};
// export const replaceToWhatsAppAPI = (
//   orderNo: string,
//   fromWhatsAppNo: string,
//   products: any,
//   cartCounts: any,
//   itemNotes: any,
//   deliveryOption: string,
//   totalAmount: string,
//   mobileNumber: string,
//   address: string,
//   discount?: number,
//   extraCharge?: number,
//   discountPercentage?: string
// ) => {
//   let orderURLText = `*New Order: ${orderNo}* \n *(_${deliveryOption}_)*\n`;

//   Object.keys(cartCounts).forEach((key: string) => {
//     if (!cartCounts[key]) return;
//     orderURLText += `• ${products[key]} x ${cartCounts[key]} ${itemNotes[key] ? `\n (Note: ${itemNotes[key]})` : ""
//       }\n`;
//   });
//   if (discount) {
//     orderURLText += `\n• Discount: *${appendCurrency(discount?.toString())}* ${discountPercentage ? `(${discountPercentage}%)` : ""
//       } \n`;
//   }
//   if (extraCharge) {
//     orderURLText += `${discount ? "" : "\n"}• Extra charge: *${appendCurrency(
//       extraCharge?.toString()
//     )}* \n`;
//   }
//   orderURLText += `\n• Total+Payable:+*${appendCurrency(
//     totalAmount
//   )}*\n\n *Address:*\n ${address}\n${mobileNumber}`;
//   document.location.href = `https://api.whatsapp.com/send?phone=${fromWhatsAppNo}&text=${encodeURIComponent(
//     orderURLText
//   )}`;
// };
export const replaceToWhatsAppAPI = (
  orderNo: string,
  fromWhatsAppNo: string,
  orderedProducts: { name: string; qty: any; price: any; note?: string }[],
  deliveryOption: string,
  paymentMethod: any,
  totalAmount: string,
  userName: string,
  mobileNumber: string,
  nameAddress: string,
  currency?: string,
  discount?: number,
  extraCharge?: number,
  orderNote?: string,
  discountPercentage?: string
) => {
  let orderURLText = `*New Order: ${orderNo}* \n *(_${deliveryOption}_)*\n`;

  orderedProducts?.forEach((orderedProduct) => {
    orderURLText += `• ${orderedProduct.name} x ${orderedProduct.qty} ${orderedProduct.note ? `\n (Note: ${orderedProduct.note})` : ""
      }\n`;
  });

  if (orderNote) {
    orderURLText += `\n• Order Note: ${orderNote}\n`;
  }

  if (paymentMethod) {
    orderURLText += `\n• Payment Method: *${paymentMethod}* \n`;
  }

  if (discount) {
    orderURLText += `\n• Discount: *${appendCurrency() !== undefined ? appendCurrency(discount?.toString()) : currency}* ${discountPercentage ? `(${discountPercentage}%)` : ""
      } \n`;
  }
  if (extraCharge) {
    orderURLText += `${discount ? "" : "\n"}• Extra charge: *${appendCurrency(
      extraCharge?.toString()
    )}* \n`;
  }
  orderURLText += `\n• Total+Payable:+*${appendCurrency() !== undefined ? appendCurrency(totalAmount) : (totalAmount + currency)}*\n\n ${userName ? `*Name:* ${userName}` : ""
    }\n ${nameAddress ? `*Address:*\n ${nameAddress}` : ""}\n${mobileNumber ? `*Mobile:* ${mobileNumber}` : ""
    }`;
  document.location.href = `https://api.whatsapp.com/send?phone=${fromWhatsAppNo}&text=${encodeURIComponent(
    orderURLText
  )}`;
};

export const getCartProducts = (productIds: string[]) => {
  return fetch(`/api/${BUSINESS_NAME}/cart?ids=${productIds.join(",")}`);
};
export const getCartItemFromSessionStorage = (key: string) => {
  return JSON.parse(sessionStorage.getItem(`${BUSINESS_NAME}~${key}`) || "{}");
};
export const getOrderMetadata = () => {
  // used # keeping separate identifier than product
  return JSON.parse(
    sessionStorage.getItem(`${BUSINESS_NAME}#LATEST_ORDER_METADATA`) ||
    "{}"
  );
};

export const getBusinessData = () => {
  return fetch(`/api/${BUSINESS_NAME}`, {
    method: "GET",
  });
};

export const getOrderId = (request: any) => {
  const urlencoded = new URLSearchParams();
  Object.keys(request).forEach((key: string) => {
    urlencoded.append(
      key,
      typeof request[key] === "string"
        ? request[key] || ""
        : JSON.stringify(request[key])
    );
  });
  return fetch(`/api/order/${BUSINESS_NAME}`, {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
    },
    body: urlencoded,
  });
};
export const setOrderMetadataInSesstionStorage = (
  metaDataObj: {
    order_id: string;
    fromWhatsAppNo: string;
    deliveryOption: string;
    currency: string
    selectedPaymentOption: string;
    itemNotes?: any;
    totalAmount: string;
    // userName: string;
    mobileNumber: string;
    nameAddress: string;
    orderNote?: string;
    couponCodeDiscount?: number;
    charges?: number;
    discountPercentage?: string;
  }
) => {
  // used # keeping separate identifier than product
  sessionStorage.setItem(
    `${BUSINESS_NAME}#LATEST_ORDER_METADATA`,
    JSON.stringify(metaDataObj)
  );
};
export const DISCOUNT_TYPE = {
  Percentage: "Percentage",
  Fixed: "Fixed",
};

export const applyCouponCode = (couponCode: string, totalAmount: number) => {
  const urlencoded = new URLSearchParams(
    `code=${couponCode}&subtotal=${totalAmount}`
  );
  return fetch(`/api/discount/${BUSINESS_NAME}`, {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
    },
    body: urlencoded,
  });
};
