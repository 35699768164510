import * as React from "react";
import { CartCount, Product, STORE_THEME, StoreInfo } from "../types/product";
import ProductDetailsCard_Default from "./productDetailsCard_default";
import ProductDetailsCard_ProGrid from "./productDetailsCard_pro-grid";
import ProductDetailsCard_ProList from "./productDetailsCard_pro-list";
import { getCookie } from "app/utils/browser";
import ProductDetailsCard_NewGrid from "./productDetailsCard_Grid-New";

interface Props {
  storeInfo: StoreInfo;
  products: Product[];
  cartCounts: CartCount;
  onAddToCart(
    product: Product,
    itemCount?: number,
    variation?: { name: string; price: string }
  ): void;
  onRemoveFromCart(
    product: Product,
    removeFull?: boolean,
    variation?: { name: string; price: string }
  ): void;
  lastProductElementRef: React.Ref<any>;
}

const ProductList: React.FC<Props> = (props: Props) => {
  const {
    storeInfo,
    products,
    onAddToCart,
    cartCounts,
    onRemoveFromCart,
    lastProductElementRef,
  } = props;

  let CardComponent: any;
  const storeTheme = getCookie("theme");
  switch (storeTheme) {
    case STORE_THEME.DEFAULT:
      CardComponent = ProductDetailsCard_Default;
      break;
    case STORE_THEME.PRO_GRID:
    case STORE_THEME.PRO_GRID_2:
      CardComponent = ProductDetailsCard_ProGrid;
      break;
    case STORE_THEME.PRO_LIST:
      CardComponent = ProductDetailsCard_ProList;
      break;
    case STORE_THEME.PRO_NEWGRID:
      CardComponent = ProductDetailsCard_NewGrid;
      break;
    default:
      CardComponent = ProductDetailsCard_Default;
  }
  return (
    <div
      className={`container ${
        storeTheme === STORE_THEME.DEFAULT
          ? ""
          : storeTheme === STORE_THEME.PRO_NEWGRID
            ? "row mx-10 gap-5 mx-auto"
            : "flex flex-wrap justify-around"
      } 
      pt-30`}
    >
      {products.map((product: Product, index: number) => (
        <CardComponent
          lastProductElementRef={
            index === products.length - 1 ? lastProductElementRef : undefined
          }
          storeInfo={storeInfo}
          key={product.id}
          product={product}
          onAddToCart={onAddToCart}
          onRemoveFromCart={onRemoveFromCart}
          cartCounts={cartCounts}
        />
      ))}
    </div>
  );
};

export default ProductList;
