import { ProductDetailsCardProps } from "app/components/product/productDetailsCard_pro-grid";
import SpecialOfferCard from "app/components/product/specialOfferCard";
import React from "react";
import Popup from "reactjs-popup";
import "./modal.css";
import SpecialOfferImage from "../components/icons/special-offer-banner.jpg";

const InitialPopup: React.FC<ProductDetailsCardProps> = (props) => {
  const { product, onAddToCart, onRemoveFromCart, cartCounts, storeInfo } =
    props;
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => setOpen(true), 1000);
  }, []);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Popup open={open} modal closeOnDocumentClick onClose={closeModal}>
      <div className="custom-modal">
        <a
          className="close-modal center-x-y"
          onClick={closeModal}
          style={{ color: "#0f6674" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
        </a>
        <div className="header">
          <img
            src={SpecialOfferImage}
            alt="special offer banner"
            className="special-offer-banner"
          />
        </div>
        <div className="content" style={{height:"fit-content"}}>
          <SpecialOfferCard
            storeInfo={storeInfo}
            product={product}
            onAddToCart={onAddToCart}
            onRemoveFromCart={onRemoveFromCart}
            cartCounts={cartCounts}
          />
        </div>
      </div>
    </Popup>
  );
};

export default InitialPopup;
