import * as React from "react";
import "./toast.css";

interface Props {
  msg: string;
}

const Toast: React.FC<Props> = ({ msg }) => {
  const toastRef = React.useRef(null);

  React.useEffect(() => {
    if (toastRef && toastRef.current) {
      // @ts-ignore
      toastRef.current.style.display = "block";
      // @ts-ignore
      setTimeout(() => {
        if (toastRef && toastRef.current) {
          // @ts-ignore
          toastRef.current.style.display = "none";
        }
      }, 2600);
    }
  });

  return (
    <div
      className="jq-toast-wrap"
      role="alert"
      aria-live="polite"
      style={{ left: "36%", top: 20 }}
    >
      <div
        className="jq-toast-single"
        style={{ textAlign: "center" }}
        ref={toastRef}
      >
        <span
          className="jq-toast-loader jq-toast-loaded"
          style={{
            OTransition: "width 2.6s ease-in",
            transition: "width 2.6s ease-in",
            backgroundColor: "#9EC600",
          }}
        />
        <span
          className="close-jq-toast-single"
          onClick={() => {
            if (toastRef?.current) {
              // @ts-ignore
              toastRef.current.style.display = "none";
            }
          }}
        >
          ×
        </span>
        <h2 className="jq-toast-heading">{msg}</h2>
      </div>
    </div>
  );
};
export default Toast;
