import { ProductShimmerCardProps } from "app/common/shimmer/productShimmerCard_pro-grid";
import React from "react";
import "app/common/shimmer/shimmer.css";

const ProductShimmerCard_Default: React.FC<ProductShimmerCardProps> = () => {
  return (
    <div className="border-1 p-10 mx-auto w-3/4" id="outercontainer">
      <div className="center-y" id="responsiveProduct">
        <div className="product-img-placeholder-default shine" />
        <div className="w-1/5 ml-15 shine br-4" style={{ height: 10 }} />
        <div className="w-1/5 ml-auto shine br-4" style={{ height: 10 }} />
        <div
          style={{ borderRadius: "50%", width: 45, height: 45 }}
          className="ml-auto mr-30 shine"
          id="shimmerdefault"
        />
      </div>
    </div>
  );
};

export default ProductShimmerCard_Default;
