import StarIcon from "app/components/icons/star.svg";
import { Product } from "app/components/types/product";
import { FancyBoxOptions } from "app/utils/fancyboxOptions";
import * as React from "react";
import { appendCurrency, isEmpty, resolveImgSrc } from "../../utils/product";
import { ProductDetailsCardProps } from "./productDetailsCard_pro-grid";
const ProductDetailsCard_Default: React.FC<ProductDetailsCardProps> = (
  props
) => {
  const { product, onAddToCart, cartCounts, storeInfo, lastProductElementRef } =
    props;
  const productImageArray = Object.values(product.images);

  const variationData: Product["variationData"] = product.variation_data
    ? JSON.parse(product.variation_data)
    : undefined;

  const [selectedOption, setSelectedOption] = React.useState(
    variationData ? variationData?.variations?.[0] : undefined
  );

  const handleVariationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;
    setSelectedOption(
      variationData.variations?.filter(
        (variation: any) => variation.name === val
      )[0]
    );
  };

  const renderVariations = () => {
    const variations = variationData?.variations;
    if (!variations?.length) return null;
    return (
      <div className="center-y justify-center mb-10 flex-column justify-content-center align-items-center">
        <div
          className="mr-10 t-body2 font-bold "
          style={{ fontSize: "17px", textTransform: "capitalize" }}
        >
          {variationData.variation_title} :
        </div>
        <select
          value={selectedOption?.name}
          className="cp form-control-range"
          onChange={handleVariationChange}
          style={{
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "5px 10px",
          }}
        >
          {variations?.map((variation: { price: string; name: string }) => (
            <option
              key={`${variation.name}_${variation.price}`}
              value={variation.name}
            >
              {`${variation.name} ( ${appendCurrency(variation.price)} )`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleAddToCart = (product: Product) => {
    onAddToCart(
      product,
      undefined,
      variationData
        ? {
            name: selectedOption?.name,
            price: selectedOption?.price,
          }
        : undefined
    );
  };

  const count =
    cartCounts[
      `${product.id}${selectedOption ? `_${selectedOption.name}` : ""}`
    ];

  const isTodaySpecial = product.today_special;
  return (
    <div
      ref={lastProductElementRef}
      className={`border-1 p-10 mx-auto relative ${
        storeInfo.price_display ? "w-3/4" : "w-1/2"
      }`}
      id="outercontainer"
    >
      {isTodaySpecial ? (
        <div className="absolute top-0 left-0 text-center z-1">
          {/*<div*/}
          {/*  className="mx-auto px-15 center-y"*/}
          {/*  style={{*/}
          {/*    width: "fit-content",*/}
          {/*    background: "#dc1d1d",*/}
          {/*    color: "white",*/}
          {/*    borderRadius: 10,*/}
          {/*    boxShadow: "rgba(255, 255, 255, 0.5) 0px 12px 10px 3px",*/}
          {/*  }}*/}
          {/*>*/}
          <img
            style={{ height: 30, width: 30 }}
            src={StarIcon}
            alt="Today's Special"
          />
          {/*<div className="mr-2 t-caption">Today's Special</div>{" "}*/}
          {/*</div>*/}
        </div>
      ) : null}
      <div className="center-y" id="responsiveProduct">
        <div className="relative">
          {productImageArray.length > 0 ? (
            <>
              {productImageArray.map((item: any, index: number) => (
                <a
                  href={resolveImgSrc(item.image)}
                  data-fancybox={`group${product.id}`}
                  data-caption={product.name}
                  data-options={JSON.stringify(FancyBoxOptions)}
                  key={index}
                >
                  <img
                    src={resolveImgSrc(item.image)}
                    alt="Product"
                    width="120px"
                    height="120px"
                    className={` ${index > 0 ? "d-none" : ""}`}
                    style={{ objectFit: "cover" }}
                  />
                </a>
              ))}
            </>
          ) : (
            <img
              src="not-found.png"
              alt="not-found"
              width="120px"
              height="120px"
            />
          )}
          {count ? (
            <div className="list-quantity-badge t-body2 bg-success text-white font-bold">
              {count}
            </div>
          ) : null}
        </div>
        <div className="font-600 w-2/5 ml-15 product-name" title={product.name}>
          <div>{product.name}</div>
          {product.description ? (
            <div
              className="t-caption text-secondary the_excerpt_desc my-1 font-400 default-view-description"
              title={product.description}
            >
              {product.description}
            </div>
          ) : null}
        </div>
        <div className="w-2/5">
          {!isEmpty(variationData) ? (
            <div className="center-y flex-col">
              {renderVariations()}
              {storeInfo.price_display ? (
                <div className="font-600 product-name">
                  {/* {selectedOption?.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(selectedOption?.price || "-")}
                    </span>
                  ) : null} */}

                  {selectedOption.price == parseInt(product.price) &&
                  product.sale_price ? (
                    <>
                      <span
                        className="t-caption text-secondary"
                        style={{
                          marginRight: 5,
                          textDecoration: "line-through",
                        }}
                      >
                        {appendCurrency(product.price || "-")}
                      </span>
                      {appendCurrency(product.sale_price)}
                    </>
                  ) : (
                    appendCurrency(selectedOption.price)
                  )}
                </div>
              ) : null}
            </div>
          ) : storeInfo.price_display ? (
            <div className="font-600 product-name text-center">
              {product.sale_price ? (
                <span
                  className="t-caption text-secondary"
                  style={{ marginRight: 5, textDecoration: "line-through" }}
                >
                  {appendCurrency(product.price || "-")}
                </span>
              ) : null}
              {appendCurrency(product.sale_price || product.price || "-")}
            </div>
          ) : null}
        </div>
        <button
          className={`btn btn-success center-x-y mr-30 ${
            storeInfo.price_display ? "" : "ml-auto"
          }`}
          style={{ borderRadius: "50%", width: 40, height: 40 }}
          onClick={() => handleAddToCart(product)}
        >
          <i
            className="fa fa-cart-plus"
            aria-hidden="true"
            style={{ fontSize: 20 }}
          />
        </button>
      </div>
    </div>
  );
};

export default ProductDetailsCard_Default;
