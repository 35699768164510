import Loader from "app/common/loader";
import Header from "app/components/Header";
import { StoreInfo } from "app/components/types/product";
import { getBusinessData, getOrderStatus } from "app/services/apiService";
import React from "react";
import ConfirmImage from "../app/components/icons/confirm.png";
import DeliveryImage from "../app/components/icons/delivery.png";
import DispatchImage from "../app/components/icons/dispatch.png";
import ProcessImage from "../app/components/icons/process.png";
import QualityImage from "../app/components/icons/quality.png";
const STATUS_COLOR = {
  FULL_FILLED: "#2da9bb",
  PENDING: "#F5998E",
};
const Status: React.FC = () => {
  const [storeInfo, setStoreInfo] = React.useState<StoreInfo>();
  const [orderNumber, setOrderNumber] = React.useState<string>("");
  const [orderTrackingInfo, setOrderTrackingInfo] = React.useState<any>();
  const [orderTrackingInfoNotFound, setOrderTrackingInfoNotFound] =
    React.useState<boolean>(false);
  const [trackedOrderNumber, setTrackedOrderNumber] =
    React.useState<string>("");
  const [loadingOrderTrackingInfo, setLoadingOrderTrackingInfo] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getBusinessData()
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw Error;
      })
      .then((data) => {
        setStoreInfo(data.store_info);
      });
  }, []);

  const trackOrder = () => {
    if (!orderNumber) return;

    trackedOrderNumber && setTrackedOrderNumber("");
    orderTrackingInfo && setOrderTrackingInfo(undefined);

    setLoadingOrderTrackingInfo(true);
    // http://finphase.online/api/order/status/ktadka?OrderID=2844
    getOrderStatus(orderNumber)
      .then((res) => {
        setOrderTrackingInfo(res);
        setTrackedOrderNumber(orderNumber);
        setOrderNumber("");
        setLoadingOrderTrackingInfo(false);
      })
      .catch(() => {
        setLoadingOrderTrackingInfo(false);
        setOrderTrackingInfoNotFound(true);
      });
  };

  // const renderOrderIdInput = () => {
  //   return (
  //     <div className="d-flex flex-col form-group items-center py-30 px-30">
  //       <input
  //         type="text"
  //         className="form-control"
  //         style={{
  //           boxShadow: "none",
  //           maxWidth: 500,
  //         }}
  //         placeholder="Enter Order ID"
  //         onChange={(e) => {
  //           if (orderTrackingInfoNotFound) setOrderTrackingInfoNotFound(false);
  //           setOrderNumber(e.target.value);
  //         }}
  //         value={orderNumber}
  //       />
  //       {orderTrackingInfoNotFound ? (
  //         <span style={{ color: "red" }}>Order does not exist</span>
  //       ) : null}
  //       <button
  //         style={{ width: 120 }}
  //         className="btn btn-primary ml-2 mt-15"
  //         onClick={trackOrder}
  //         disabled={!orderNumber || loadingOrderTrackingInfo}
  //       >
  //         Track Order
  //       </button>
  //     </div>
  //   );
  // };

  const renderOrderTrackingInfo = () => {
    if (!orderTrackingInfo) return null;

    /*
      * "order_status": {
          "0": "New",
          "1": "Confirmed",
          "3": "Pickup Started",
          "4": "Picked Up",
          "5": "In Delivery",
          "6": "Arrived Destination",
          "10": "Delivered",
          "11": "Canceled"
        }
      * */ 

    const { data } = orderTrackingInfo;
    const orderStatus = data?.status?.toString();

    // const isOrderCanceled = orderStatus === "11";

    return (
      <div className="shipment">
        <div className="confirm">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "0"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={ConfirmImage} alt="confirm order" />
          </div>
          <span
            className="line"
            style={{
              background:
                orderStatus > "0"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          />
          <p>Order Placed</p>
        </div>
        <div className="confirm">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "1"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={ConfirmImage} alt="confirm order" />
          </div>
          <span
            className="line"
            style={{
              background:
                orderStatus > "1"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          />
          <p>Confirmed Order</p>
        </div>
        <div className="process">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "4"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={ProcessImage} alt="process order" />
          </div>
          <span
            className="line"
            style={{
              background:
                orderStatus > "4"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          />
          <p>Processing Order</p>
        </div>
        <div className="quality">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "4"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={QualityImage} alt="quality check" />
          </div>
          <span
            className="line"
            style={{
              background:
                orderStatus > "4"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          />
          <p>Order ready to Pick Up</p>
        </div>
        <div className="dispatch">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "5"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={DispatchImage} alt="dispatch product" />
          </div>
          <span
            className="line"
            style={{
              background:
                orderStatus > "5"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          />
          <p>Dispatched Item</p>
        </div>
        <div className="delivery">
          <div
            className="imgcircle"
            style={{
              background:
                orderStatus >= "10"
                  ? STATUS_COLOR.FULL_FILLED
                  : STATUS_COLOR.PENDING,
            }}
          >
            <img src={DeliveryImage} alt="delivery" />
          </div>
          <p>Product Delivered</p>
        </div>
        <div className="clear" />
      </div>
    );
  };

  const renderOrderTrackingContent = () => {
    return (
      <div className="products_items_area">
        <div
          className={`content ${orderTrackingInfo ? "border-1" : ""}`}
          style={
            orderTrackingInfo ? undefined : { height: 290, background: "none" }
          }
        >
          <div className="content1">
            <div className="d-lg-flex items-center justify-content-between">
              <h2>
                Order Tracking
                {orderTrackingInfo ? `: Order No #${trackedOrderNumber}` : ""}
              </h2>
              <div>
                <div className="d-flex float-right">
                  <input
                    type="text"
                    className="form-control mr-1"
                    style={{
                      boxShadow: "none",
                      maxWidth: 500,
                      minWidth: 200,
                    }}
                    placeholder="Enter Order ID"
                    onChange={(e) => {
                      if (orderTrackingInfoNotFound)
                        setOrderTrackingInfoNotFound(false);
                      setOrderNumber(e.target.value);
                    }}
                    value={orderNumber}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) {
                        trackOrder();
                      }
                    }}
                  />

                  <button
                    style={{ width: 80, padding: "5px 0px" }}
                    className="btn btn-outline-light t-caption"
                    onClick={trackOrder}
                    disabled={!orderNumber || loadingOrderTrackingInfo}
                  >
                    Go
                  </button>
                </div>
                {orderTrackingInfoNotFound ? (
                  <div style={{ color: "#f3afaf" }}>Order does not exist</div>
                ) : null}
              </div>
            </div>
            <div className="d-block" />
          </div>
          {orderTrackingInfo ? (
            <div className="content2">
              <div className="hidden content2-header1">
                <p>
                  Shipped Via : <span>Ipsum Dolor</span>
                </p>
              </div>
              <div className="content2-header1">
                <p>
                  Status :{" "}
                  <span>
                    {
                      orderTrackingInfo?.order_status?.[
                        orderTrackingInfo?.data?.status
                      ]
                    }
                  </span>
                </p>
              </div>
              <div className="hidden content2-header1">
                <p>
                  Expected Date : <span>7-NOV-2015</span>
                </p>
              </div>
              <div className="clear" />
            </div>
          ) : null}
          <div className="content3 bg-white">
            {loadingOrderTrackingInfo ? (
              <Loader />
            ) : orderTrackingInfo ? (
              renderOrderTrackingInfo()
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  if (!storeInfo) return <Loader />;

  return (
    <div>
      <Header storeInfo={storeInfo} />
      {renderOrderTrackingContent()}
    </div>
  );
};

export default Status;
