import React from "react";
import "./App.css";
import ProductDashboard from "./components/product/productDashboard";
import { Route, Routes } from "react-router-dom";
// @ts-ignore
import Success from "order-status/Success.tsx";
import Failed from "order-status/Failed";
import { BUSINESS_NAME } from "./services/apiService";
import Status from "order-status/Status";

function App() {
  return (
    <>
      <Routes>
        <Route
          path={`${BUSINESS_NAME}/order-successful`}
          element={<Success />}
        />
        <Route path={`${BUSINESS_NAME}/order-failed`} element={<Failed />} />
        <Route path={`${BUSINESS_NAME}/order-track`} element={<Status />} />
        <Route path="/:id" element={<ProductDashboard />} />
        <Route path="/:id/:a" element={<ProductDashboard />} />
        <Route path="/" element={<ProductDashboard />} />
      </Routes>
      ;
    </>
  );
}

export default App;
