import { Pagination, Product } from "app/components/types/product";
import { BUSINESS_NAME } from "app/services/apiService";
import axios from "axios";
import React from "react";

const ALL_CATEGORY = "All";
const TODAY_SPECIAL_CATEGORY = "Today's Special";
const EXCLUDE_CATEGORIES = [ALL_CATEGORY, TODAY_SPECIAL_CATEGORY];

export const useProducts = ({
  category,
  pageNumber,
  keyword,
}: {
  category: string;
  pageNumber: number;
  keyword: string;
}) => {
  const [loadingProducts, setLoadingProducts] = React.useState<boolean>(false);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<Product[] | undefined>(
    undefined
  );

  React.useEffect(() => {
    setProducts([]);
  }, [category, keyword]);

  const getUrl = () => {
    const params = [`page=${encodeURIComponent(pageNumber)}`];
    if (category && !EXCLUDE_CATEGORIES.includes(category)) {
      params.push(`category=${encodeURIComponent(category)}`);
    } else if (category === TODAY_SPECIAL_CATEGORY) {
      params.push("today_special=1");
    }

    if (keyword) {
      params.push(`search=${keyword}`);
    }
    return `/api/${BUSINESS_NAME}/product?${params.join("&")}`;
  };

  React.useEffect(() => {
    setLoadingProducts(true);
    let cancelToken: any;
    axios({
      method: "GET",
      url: getUrl(),
      cancelToken: new axios.CancelToken((c) => (cancelToken = c)),
    })
      .then((res: any) => {
        if (res.status === 200) return res.data;
        else throw Error;
      })
      .then((res: any) => {
        const paginationInfo = res?.pagination || ({} as Pagination);
        const prods = res?.products || {};
        const more =
          ((paginationInfo?.cur_page || 0) + 1) *
          (paginationInfo?.per_page || 0) <=
          paginationInfo.total_pro || 0;
        setProducts((prevProducts) => {
          if (prevProducts)
            return [...prevProducts, ...Object.values(prods)] as Product[];
          else return Object.values(prods) as Product[];
        });
        setHasMore(Boolean(more));
        setLoadingProducts(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoadingProducts(false);
      });
    return () => cancelToken?.();
  }, [pageNumber, keyword, category]);
  return { loadingProducts, hasMore, products };
};
