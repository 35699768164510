import { ProductShimmerCardProps } from "app/common/shimmer/productShimmerCard_pro-grid";
import React from "react";
import "app/common/shimmer/shimmer.css";

const ProductShimmerCard_ProList: React.FC<ProductShimmerCardProps> = () => {
  return (
    <div className="top-buffer sc-product-item product-details-pro-list mr-15">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-5 image-wrapper shine"></div>
            <div
              className="col-7 pb-2 pt-2 flex-col flex"
              style={{ paddingLeft: 30 }}
            >
              <div
                style={{ height: 15, width: "80%" }}
                className="shine br-4"
              />
              <div
                style={{ height: 15, width: "40%" }}
                className="shine mt-auto br-4 mb-10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="add-cart-btn sc-add-to-cart shine" />
    </div>
  );
};

export default ProductShimmerCard_ProList;
