import StarIcon from "app/components/icons/star.svg";
import * as React from "react";
import { appendCurrency, resolveImgSrc } from "../../../utils/product";
import { Product } from "../../types/product";

interface Props {
  product: Product;
  count: number;
  onAddProduct(product: Product, count?: number): void;
  onRemoveFromCart(product: Product, removeFull?: boolean): void;
  note: string;
  onNoteChange(note: string): void;
}

const CartProductItem: React.FC<Props> = (props: Props) => {
  const { product, onRemoveFromCart, onAddProduct, count, note, onNoteChange } =
    props;
  var productPrice = "0";
  const [itemCount, setItemCount] = React.useState<number>(count);
  const productImageArray = Object.values(product.images);

  if (
    product.id &&
    product.id.split &&
    product.id.split("_")[1] === undefined
  ) {
    productPrice = product.sale_price || product.price;
  } else {
    if (product.variation_data) {
      JSON.parse(product.variation_data).variations.map((i: any) => {
        if (i.name === product.id.split("_")[1]) {
          if (i.price === product.price.split(".")[0]) {
            if (product.sale_price) {
              productPrice = product.sale_price;
            } else {
              productPrice = i.price;
            }
          } else {
            productPrice = i.price;
          }
        }
      });
    }
  }

  React.useEffect(() => {
    setItemCount(count);
  }, [count]);

  return (
    <div className="mb-10 border-1 p-10 relative br-4 relative">
      <div className="absolute top-0 left-0 text-center">
        {/*<div*/}
        {/*  className="mx-auto px-15 center-y"*/}
        {/*  style={{*/}
        {/*    width: "fit-content",*/}
        {/*    background: "#dc1d1d",*/}
        {/*    color: "white",*/}
        {/*    borderRadius: 10,*/}
        {/*    boxShadow: "rgba(255, 255, 255, 0.5) 0px 12px 10px 3px",*/}
        {/*  }}*/}
        {/*>*/}
        <img
          style={{ height: 20, width: 20 }}
          src={StarIcon}
          alt="Today's Special"
        />
        {/*<div className="mr-2" style={{ fontSize: 10 }}>*/}
        {/*  Today's Special*/}
        {/*</div>*/}
        {/*</div>*/}
      </div>
      <button
        type="button"
        className="cart-remove"
        onClick={() => onRemoveFromCart(product, true)}
      >
        <i className="fa fa-trash trash" aria-hidden="true" />
      </button>
      {productImageArray.length > 0 ? (
        <img
          src={resolveImgSrc(productImageArray[0].image)}
          width={100}
          height={100}
          className="block"
          alt={product.name}
        />
      ) : (
        <img
          src="not-found.png"
          width={100}
          height={100}
          className="block"
          alt={product.name}
        />
      )}
      <div style={{ marginBottom: 5 }}>
        {product.name}{" "}
        {product.variation
          ? `(${product.variation} ${product.variation_data ? JSON.parse(product.variation_data).variation_title : ""})`
          : ""}
      </div>
      <div
        className="t-caption text-secondary"
        dangerouslySetInnerHTML={{ __html: product.product_description_e }}
      />
      <div className="flex cart-product-item-section">
        <div className="add-note-section mr-30">
          <input
            value={note || ""}
            onChange={(e) => onNoteChange(e.target.value)}
            name="addNote"
            className="form-control"
            style={{ boxShadow: "none", fontSize: 12, padding: "5px 10px" }}
            id="addNote"
            placeholder="Add Note..."
            spellCheck="false"
            maxLength={255}
          />
        </div>
        <div className="ml-auto t-body1">
          {appendCurrency(productPrice)} x{" "}
          <input
            type="number"
            min="1"
            max="1000"
            className="cart-item-qty"
            value={itemCount || ""}
            onChange={(e) => {
              setItemCount(+e.target.value);
              e.target.value && onAddProduct(product, +e.target.value);
            }}
          />{" "}
          ={" "}
          {appendCurrency(
            productPrice && (+productPrice * +itemCount).toFixed(3)
          )}
        </div>
      </div>
    </div>
  );
};

export default CartProductItem;
