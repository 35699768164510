import { FancyBoxOptions } from "app/utils/fancyboxOptions";
import * as React from "react";
import { appendCurrency, isEmpty, resolveImgSrc } from "../../utils/product";
import AddItemIcon from "../icons/addItemIcon";
import RemoveItemIcon from "../icons/removeItemIcon";
import { CartCount, Product, STORE_THEME, StoreInfo } from "../types/product";
import StarIcon from "../icons/star.svg";

// Image Preview - https://codepen.io/ongtiffany/pen/BoOeQV

export interface ProductDetailsCardProps {
  storeInfo: StoreInfo;
  product: Product;
  cartCounts: CartCount;
  onAddToCart(
    product: Product,
    itemCount?: number,
    variation?: { name: string; price: string }
  ): void;
  onRemoveFromCart(
    product: Product,
    removeFull?: boolean,
    variation?: { name: string; price: string }
  ): void;
  inPopup?: boolean;
  lastProductElementRef?: React.Ref<any>;
}

// THEME: pro-grid

const ProductDetailsCard_ProGrid: React.FC<ProductDetailsCardProps> = (
  props: ProductDetailsCardProps
) => {
  const {
    product,
    onAddToCart,
    onRemoveFromCart,
    cartCounts,
    storeInfo,
    inPopup,
    lastProductElementRef,
  } = props;
  const productImageArray = Object.values(product.images);
  const isTodaySpecial = product.today_special;
  const variationData: Product["variationData"] = product.variation_data
    ? JSON.parse(product.variation_data)
    : undefined;

  const [selectedOption, setSelectedOption] = React.useState(
    variationData ? variationData?.variations?.[0] : undefined
  );

  const handleVariationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;
    setSelectedOption(
      variationData.variations?.filter(
        (variation: any) => variation.name === val
      )[0]
    );
  };

  const renderVariations = () => {
    const variations = variationData.variations;
    if (!variations?.length) return null;
    return (
      <div className="flex flex-col align-items-center mb-10 px-2">
        <div
          className="mr-10 t-body2 font-bold"
          style={{
            fontSize: "17px",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {variationData.variation_title}
        </div>
        <select
          value={selectedOption?.name}
          className="cp form-control-range"
          onChange={handleVariationChange}
          style={{
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "5px 10px",
            width: "fit-content",
          }}
        >
          {variations?.map((variation: { price: string; name: string }) => (
            <option
              key={`${variation.name}_${variation.price}`}
              value={variation.name}
            >
              {`${variation.name} ( ${appendCurrency(variation.price)} )`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const _callFunc = (func: any, product: Product) => {
    func(
      product,
      undefined,
      variationData
        ? {
            name: selectedOption?.name,
            price: selectedOption?.price,
          }
        : undefined
    );
  };

  const handleAddToCart = (product: Product) => {
    _callFunc(onAddToCart, product);
  };

  const handleRemoveFromCart = (product: Product) => {
    _callFunc(onRemoveFromCart, product);
  };

  const count =
    cartCounts[
      `${product.id}${selectedOption ? `_${selectedOption.name}` : ""}`
    ];

  return (
    <div
      ref={lastProductElementRef}
      className={`${
        storeInfo.store_theme === STORE_THEME.PRO_GRID_2
          ? "product-details-pro-grid-2"
          : "product-details"
      } ${inPopup ? "mx-auto" : ""}`}
      style={inPopup ? { width: "50%" } : undefined}
    >
      <div className="product-item flex flex-col relative">
        {productImageArray.length > 0 ? (
          <>
            {productImageArray.map((item: any, index: number) => (
              <a
                href={resolveImgSrc(item.image)}
                data-fancybox={`group${product.id}`}
                data-caption={product.name}
                data-options={JSON.stringify(FancyBoxOptions)}
                key={index}
              >
                <img
                  src={resolveImgSrc(item.image)}
                  alt="Product"
                  className={`product-img ${index > 0 ? "d-none" : ""}`}
                  style={{ objectFit: "cover" }}
                />
                {isTodaySpecial ? (
                  <div className="absolute top-0 left-0 text-center">
                    <img
                      width={30}
                      height={30}
                      src={StarIcon}
                      alt="Today's Special"
                    />
                  </div>
                ) : null}
              </a>
            ))}
          </>
        ) : (
          <img src="not-found.png" className="product-img " alt="not-found" />
        )}
        <div className="py-10">
          <div className="text-center product-name">{product.name}</div>
          {product.description ? (
            <div
              className="the_excerpt_desc t-caption text-secondary m-2"
              title={product.description}
            >
              {product.description}
            </div>
          ) : null}
        </div>
        <div className="mt-auto">
          {!isEmpty(variationData) ? (
            <div className="center-y flex-col">
              {renderVariations()}
              {storeInfo.price_display ? (
                <div className="font-600 product-name">
                  {/* {selectedOption?.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(selectedOption?.price || "-")}
                    </span>
                  ) : null} */}

                  {selectedOption.price == parseInt(product.price) &&
                  product.sale_price ? (
                    <>
                      <span
                        className="t-caption text-secondary"
                        style={{
                          marginRight: 5,
                          textDecoration: "line-through",
                        }}
                      >
                        {appendCurrency(product.price || "-")}
                      </span>
                      {appendCurrency(product.sale_price)}
                    </>
                  ) : (
                    appendCurrency(selectedOption.price)
                  )}
                </div>
              ) : null}
            </div>
          ) : storeInfo.price_display ? (
            <div className="font-600 product-name text-center">
              {product.sale_price ? (
                <span
                  className="t-caption text-secondary"
                  style={{ marginRight: 5, textDecoration: "line-through" }}
                >
                  {appendCurrency(product.price || "-")}
                </span>
              ) : null}
              {appendCurrency(product.sale_price || product.price || "-")}
            </div>
          ) : null}
          {count ? (
            <div className="center-y justify-center py-10">
              <RemoveItemIcon
                onClick={() => handleRemoveFromCart(product)}
                className="cp"
              />
              <span className="font-bold t-body2 mx-15 no-select">{count}</span>
              <AddItemIcon
                onClick={() => handleAddToCart(product)}
                className="cp"
              />
            </div>
          ) : (
            <button
              className="cart-btn my-5 cart-btn-success cart-btn-add center-y m-auto"
              onClick={() => handleAddToCart(product)}
            >
              <i
                className="fa fa-shopping-cart add-to-cart-icon"
                aria-hidden="true"
              />
              <span className="font-bold" style={{ marginLeft: 5 }}>
                Add to Cart
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsCard_ProGrid;
