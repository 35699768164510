import StarIcon from "app/components/icons/star.svg";
import { Product } from "app/components/types/product";
import { FancyBoxOptions } from "app/utils/fancyboxOptions";
import * as React from "react";
import { appendCurrency, isEmpty, resolveImgSrc } from "../../utils/product";
import { ProductDetailsCardProps } from "./productDetailsCard_pro-grid";
import "./style/product-details-card.css";

const ProductDetailsCard_ProList: React.FC<ProductDetailsCardProps> = (
  props
) => {
  const { product, onAddToCart, cartCounts, storeInfo, lastProductElementRef } =
    props;
  const productImageArray = Object.values(product.images);

  const variationData: Product["variationData"] = product.variation_data
    ? JSON.parse(product.variation_data)
    : undefined;

  const [selectedOption, setSelectedOption] = React.useState(
    variationData ? variationData?.variations?.[0] : undefined
  );

  const handleVariationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;
    setSelectedOption(
      variationData.variations?.filter(
        (variation: any) => variation.name === val
      )[0]
    );
  };
  const renderVariations = () => {
    const variations = variationData?.variations;
    if (!variations?.length) return null;
    return (
      <div className="center-y mb-10 flex-column">
        <div
          className="mr-10 t-body2 font-bold"
          style={{ textTransform: "capitalize", fontSize: "17px" }}
        >
          {variationData.variation_title}:
        </div>
        <select
          value={selectedOption?.name}
          className="cp form-control-range"
          onChange={handleVariationChange}
          style={{
            marginRight: 10,
            borderRadius: "0.25rem",
            border: "1px solid #ced4da",
            padding: "5px 10px",
          }}
        >
          {variations?.map((variation: { price: string; name: string }) => (
            <option
              key={`${variation.name}_${variation.price}`}
              value={variation.name}
            >
              {`${variation.name} ( ${appendCurrency(variation.price)} )`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleAddToCart = (product: Product) => {
    onAddToCart(
      product,
      undefined,
      variationData
        ? {
            name: selectedOption?.name,
            price: selectedOption?.price,
          }
        : undefined
    );
  };

  const count =
    cartCounts[
      `${product.id}${selectedOption ? `_${selectedOption.name}` : ""}`
    ];

  const isTodaySpecial = product?.today_special;

  return (
    <div
      ref={lastProductElementRef}
      className="top-buffer sc-product-item product-details-pro-list mr-15"
    >
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-5">
              <div className="image-wrapper relative">
                {count ? (
                  <div className="quantity-badge bg-success text-white">
                    {count}
                  </div>
                ) : null}
                {productImageArray.length > 0 ? (
                  <>
                    {productImageArray.map((item: any, index: number) => {
                      return (
                        <a
                          href={resolveImgSrc(item.image)}
                          data-fancybox={`group${product.id}`}
                          data-caption={product.name}
                          data-options={JSON.stringify(FancyBoxOptions)}
                          key={index}
                        >
                          <img
                            src={resolveImgSrc(item.image)}
                            alt="Product"
                            width="100%"
                            height="100%"
                            className={`${index > 0 ? "d-none" : ""}`}
                            style={{ objectFit: "cover" }}
                          />
                          {isTodaySpecial ? (
                            <div className="absolute top-0 right-0 text-center">
                              {/* <div
                           className="mx-auto px-15 center-y"
                            style={{
                              width: "fit-content",
                              background: "#dc1d1d",
                              color: "white",
                              borderRadius: 10,
                              boxShadow:
                                "rgba(255, 255, 255, 0.5) 0px 12px 10px 3px",
                            }}
                          > */}
                              <img
                                style={{ height: 30, width: 30 }}
                                src={StarIcon}
                                alt="Today's Special"
                              />
                              {/*<div className="mr-2 t-caption">Today's Special</div>{" "}
                          </div>*/}
                            </div>
                          ) : null}
                        </a>
                      );
                    })}
                  </>
                ) : (
                  <img
                    src="not-found.png"
                    alt="not-found"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                )}
              </div>
            </div>
            <div
              className="col-7 pb-2 pt-2 flex-col flex"
              style={{ paddingLeft: 30 }}
            >
              <div className="product-title product-name mb-1">
                <span>{product.name}</span>
              </div>
              {product.description ? (
                <div
                  className="t-caption text-secondary the_excerpt_desc mb-1"
                  title={product.description}
                >
                  {product.description}
                </div>
              ) : null}
              {!isEmpty(variationData) ? (
                <div className="center-y flex-col">
                  {renderVariations()}
                  {storeInfo.price_display ? (
                    <div className="font-600 product-name">
                      {/* {selectedOption?.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(selectedOption?.price || "-")}
                    </span>
                  ) : null} */}

                      {selectedOption.price == parseInt(product.price) &&
                      product.sale_price ? (
                        <>
                          <span
                            className="t-caption text-secondary"
                            style={{
                              marginRight: 5,
                              textDecoration: "line-through",
                            }}
                          >
                            {appendCurrency(product.price || "-")}
                          </span>
                          {appendCurrency(product.sale_price)}
                        </>
                      ) : (
                        appendCurrency(selectedOption.price)
                      )}
                    </div>
                  ) : null}
                </div>
              ) : storeInfo.price_display ? (
                <div className="font-600 product-name text-center">
                  {product.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(product.price || "-")}
                    </span>
                  ) : null}
                  {appendCurrency(product.sale_price || product.price || "-")}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-success add-cart-btn sc-add-to-cart"
        onClick={() => handleAddToCart(product)}
      >
        <i
          className="fa fa-cart-plus"
          aria-hidden="true"
          style={{ fontSize: 20 }}
        />
      </button>
    </div>
  );
};

export default ProductDetailsCard_ProList;
