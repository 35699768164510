import Header from "app/components/Header";
import { Link } from "react-router-dom";
import {
  BUSINESS_NAME,
  getBusinessData,
  replaceToWhatsAppAPI,
  getCartItemFromSessionStorage,
  getOrderMetadata,
} from "app/services/apiService";
import React from "react";
import { clearLastOrderDataFully, OrderMetadata } from "app/utils/product";
import { StoreInfo } from "app/components/types/product";
import ThanksImage from "../app/components/icons/thanks.png";
import Loader from "app/common/loader";
function Success() {
  const [storeInfo, setStoreInfo] = React.useState<StoreInfo>();
  const orderMetadata = getOrderMetadata();
  if (Object.values(orderMetadata).length < 0) {
    window.location.href = `${window.location.origin}/${BUSINESS_NAME}`;
  } else {
  }
  // Name, qty, price --> {name: '', qty: '', price: ''}
  const orderedProducts: {
    name: string;
    qty: any;
    price: any;
    note?: string;
  }[] = [];
  Object.keys(sessionStorage)?.forEach((key: string) => {
    if (key?.includes(`${BUSINESS_NAME}~`)) {
      const data = getCartItemFromSessionStorage(key?.split("~")[1]); // product id
      orderedProducts.push({
        name: `${data?.product?.name}${
          data?.product?.variation ? `_${data?.product?.variation}` : ""
        }`,
        qty: data?.count || 0,
        price: data?.product?.sale_price || data?.product?.price,
      });
    }
  });
  React.useEffect(() => {
    getBusinessData()
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw Error;
      })
      .then((data) => {
        setStoreInfo(data.store_info);
      });
  }, []);
  const handleOrderOnWhatsApp = () => {
    clearLastOrderDataFully();
    const orderMetadataLocal = orderMetadata as OrderMetadata;
    if (!orderMetadataLocal) return;
    replaceToWhatsAppAPI(
      orderMetadataLocal.order_id,
      orderMetadataLocal.fromWhatsAppNo,
      orderedProducts,
      orderMetadataLocal.deliveryOption,
      orderMetadataLocal.selectedPaymentOption,
      orderMetadataLocal.totalAmount,
      orderMetadataLocal.userName,
      orderMetadataLocal.mobileNumber,
      orderMetadataLocal.nameAddress,
      orderMetadataLocal.currency,
      orderMetadataLocal.userOrderNote,
      orderMetadataLocal.couponCodeDiscount,
      orderMetadataLocal.charges,
      orderMetadataLocal.discountPercentage
    );
  };
  const renderOrderedProducts = () => {
    if (!orderedProducts?.length) return null;
    return orderedProducts?.map((product: any) => {
      return (
        <h6>
          {product.name} X {product.qty}
          <span className="cart-pop-price">
            {+product.price + " " + orderMetadata.currency + " "}
          </span>
        </h6>
      );
    });
  };
  const renderOrderInfo = () => {
    let prevdata = sessionStorage.getItem("prev_store");
    return (
      <div className="container">
        <div className="row thanks_pp">
          <div className="col-md-12">
            <img src={ThanksImage} alt="thanks"/>
            <h6 className="text-center p-4">
              Your Order{" "}
              {orderMetadata?.order_id ? `(#${orderMetadata?.order_id})` : ""}{" "}
              has been placed Successfully :) <br />
              <br />
              <span className="pt-4">
                {renderOrderedProducts()}
                {orderMetadata.totalAmount ? (
                  <strong>
                    Total:{" "}
                    <span className="cart-pop-price">
                      {+orderMetadata.totalAmount +
                        " " +
                        orderMetadata.currency +
                        " "}
                    </span>
                  </strong>
                ) : null}
                {orderMetadata?.userOrderNote ? (
                  <p className="mt-2">{orderMetadata?.userOrderNote}</p>
                ) : null}
                <strong>Delivery Info: 1 Hour</strong>
              </span>
            </h6>
          </div>

          <div className="col-md-12">
            <div className="btns mt-3">
              <Link
                to={`/${prevdata}`}
                className="homepage mr-1"
                onClick={clearLastOrderDataFully}
              >
                Go To Homepage
              </Link>
              <a
                href="#"
                className="cp whatsapp mr-1"
                onClick={handleOrderOnWhatsApp}
              >
                Order By WhatsApp
              </a>
              <Link to={`/${prevdata}/order-track`} className="cp whatsapp">
                Track Order
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (!storeInfo) return <Loader />;

  return (
    <div>
      {Object.values(orderMetadata).length <= 0 &&
        (window.location.href = `${window.location.origin}/${BUSINESS_NAME}`)}
      {Object.values(orderMetadata).length > 0 && (
        <Header storeInfo={storeInfo} />
      )}
      {Object.values(orderMetadata).length > 0 && renderOrderInfo()}
    </div>
  );
}
export default Success;
