import Header from "app/components/Header";
import React from "react";
import { StoreInfo } from "app/components/types/product";
import {
  getBusinessData
} from "app/services/apiService";
import FailedImage from "../app/components/icons/failed.png";
import { Link } from "react-router-dom";
import Loader from "app/common/loader";

function Failed() {
  const [storeInfo, setStoreInfo] = React.useState<StoreInfo>();
  let prevdata = sessionStorage.getItem("prev_store");
  React.useEffect(() => {
    getBusinessData()
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw Error;
      })
      .then((data) => {
        setStoreInfo(data.store_info);
      });
  }, []);
  const renderOrderInfo = () => {
    return (
      <div className="container">
        <div className="row thanks_pp">
          <div className="col-md-12">
            <img src={FailedImage} alt="fail " />
            <h3 className="text-center p-4">
              Payment failed please try again :(
            </h3>
          </div>
          <div className="col-md-12">
            <div className="btns mt-3">
              <Link to={`/${prevdata}`} className="homepage">
                Go To Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (!storeInfo) return <Loader />;

  return (
    <div>
      <Header storeInfo={storeInfo} />
      {renderOrderInfo()}
    </div>
  );
}
export default Failed;
