import * as React from "react";
import { resolveImgSrc } from "../utils/product";
import { StoreInfo } from "./types/product";

interface Props {
  storeInfo: StoreInfo;
}

function Header(props: Props) {
  const {
    storeInfo: {
      store_about,
      store_bgcolor,
      store_color,
      store_logo,
      store_name,
      logo_display,
      title_display,
    },
  } = props;

  return (
    <>
      <header style={{ backgroundColor: store_bgcolor }}>
        <div className="container">
          {logo_display ? (
            <div className="logo">
              <img
                src={resolveImgSrc(store_logo)}
                alt="Brand logo"
                height="120"
              />
            </div>
          ) : null}
          {title_display ? (
            <div className="text-center" style={{ color: store_color }}>
              <div className={`${logo_display ? "mt-20" : ""} text-3xl`}>
                {store_name}
              </div>
            </div>
          ) : null}
        </div>
      </header>
      <div
        className="text-center text-secondary"
        style={{ fontSize: 15, fontWeight: 400, margin: "7px 20px" }}
      >
        <i>{store_about}</i>
      </div>
    </>
  );
}

export default Header;
