import InitialPopup from "app/common/InitialPopup";
import ProductShimmer from "app/common/shimmer/productShimmer";
import { useProducts } from "app/hoc/useProducts";
import {
  getCartItemCount,
  getCookie,
  removeCartItem,
  setCartItemCount,
} from "app/utils/browser";
import * as React from "react";
import Loader from "../../common/loader";
import { BUSINESS_NAME, getBusinessData } from "../../services/apiService";
import Header from "../Header";
import {
  API_DATA,
  CartCount,
  CartOptions,
  Product,
  STORE_CURRENCY_POSITION,
  StoreInfo,
} from "../types/product";
import Cart from "./cart/cart";
import CategoriesNavBar from "./categories/categoriesNavBar";
import ProductList from "./productList";
import Toast from "./toast/toast";
import GridChange from "app/common/GridChange";

export const ALL_CATEGORY = "All";
export const TODAY_SPECIAL_CATEGORY = "Today's Special";
const CLEAR_CART_ON_NEXT_LOAD = "CLEAR_CART_ON_NEXT_LOAD";
const TRUE = "true";

let extraCharges: API_DATA["extra_charges"];

const ProductDashboard: React.FC = () => {
  const navRef = React.useRef();

  const [currentTheme, setcurrentTheme] = React.useState("default");
  const [storeInfo, setStoreInfo] = React.useState<StoreInfo>({} as StoreInfo),
    [cartOptions, setCartOptions] = React.useState<CartOptions>(
      {} as CartOptions
    ),
    [categories, setCategories] = React.useState<string[]>([]),
    [specialOffer, setSpecialOffer] = React.useState<Product | undefined>(
      undefined
    ),
    [cartCounts, setCartCounts] = React.useState<CartCount>({} as CartCount),
    // [cartProducts, setCartProducts] = React.useState<Product[]>([]),
    [activeCategory, setActiveCategory] = React.useState<string>(ALL_CATEGORY),
    [keyword, setKeyword] = React.useState<string>(""),
    [loading, setLoading] = React.useState<boolean>(false),
    [notFound, setNotFount] = React.useState<boolean>(false),
    [error, setError] = React.useState<object>({});

  React.useEffect(() => {
    if (sessionStorage.getItem(CLEAR_CART_ON_NEXT_LOAD) === TRUE) {
      sessionStorage.removeItem(CLEAR_CART_ON_NEXT_LOAD);
      Object.keys(sessionStorage).forEach((key) => {
        if (key?.includes(`${BUSINESS_NAME}~`)) {
          sessionStorage.removeItem(key);
        }
      });
    }
    const counts = {} as CartCount;
    Object.keys(sessionStorage).forEach((key) => {
      if (key?.includes(`${BUSINESS_NAME}~`)) {
        const splits = key.split("~");
        if (splits.length > 1) {
          // splits[1] --> id_variation
          counts[splits[1]] = getCartItemCount(splits[1]);
        }
      }
    });

    setCartCounts(counts);

    setLoading(true);
    getBusinessData()
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw Error;
      })
      .then((data: API_DATA) => {
        const store_info = data?.store_info;
        window["$currency"] = store_info?.store_currency; // declaring currency this way - So that it can be used in every component
        window["$currency_position"] =
          store_info?.store_currency_position || STORE_CURRENCY_POSITION.before;

        extraCharges = data?.extra_charges;
        setStoreInfo(store_info || ({} as StoreInfo));
        setCartOptions(data?.cart_options || ({} as CartOptions));
        const cats = data?.categories || {};
        if (Object.values(cats).length) {
          const d = Object.values(cats);
          if (data?.today_special) d.unshift(TODAY_SPECIAL_CATEGORY);
          d.unshift(ALL_CATEGORY);
          setCategories(d);
        }
        // setCartCounts(counts as CartCount);
        // setProducts(Object.values(prods));
        setSpecialOffer(
          data?.special_offer?.id ? data.special_offer : undefined
        );
        setLoading(false);
      })
      .catch(() => {
        setNotFount(true);
        setLoading(false);
      });
  }, []);
  const storeTheme = getCookie("theme");

  //**********************************************************************************************************************************//
  //*                                                      Pagination                                                                *//
  //**********************************************************************************************************************************//

  const [pageNumber, setPageNumber] = React.useState<number>(0);

  const { loadingProducts, hasMore, products } = useProducts({
    category: activeCategory,
    pageNumber,
    keyword,
  });

  const observer = React.useRef<any>();
  const lastProductElementRef = React.useCallback(
    (node: any) => {
      if (loadingProducts) return;
      if (observer.current) {
        observer.current?.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current?.observe?.(node);
    },
    [loadingProducts, hasMore]
  );

  React.useEffect(() => {
    if (!products || !products?.length) return;
    const counts = {} as CartCount;
    Object.values(products).forEach((product: Product) => {
      const variationData: Product["variationData"] = product?.variation_data
        ? JSON.parse(product.variation_data)
        : undefined;
      if (variationData) {
        const variations = variationData.variations;
        if (variations?.length) {
          variations.forEach((variation: any) => {
            const item = `${product.id}_${variation.name}`;
            counts[item] = getCartItemCount(item);
          });
        }
      }
      counts[product?.id] = getCartItemCount(product?.id);
    });
    setCartCounts((prevState: CartCount) => ({
      ...prevState,
      ...counts,
    }));
  }, [products]);

  //**********************************************************************************************************************************//
  //*                                                                                                                                *//
  //**********************************************************************************************************************************//

  React.useEffect(() => {
    if (Object.keys(error)?.length) setError({});
  }, [cartCounts, error]);

  const addProductToCart = (
    product: Product,
    itemCount?: number,
    variation?: { name: string; price: string }
  ) => {
    const item = `${product.id}${variation ? `_${variation.name}` : ""}`;
    const count: any = !itemCount && getCartItemCount(item);
    setCartItemCount(item, itemCount || count + 1, product);
    setCartCounts((d: CartCount) => ({
      ...d,
      [item]: itemCount || count + 1,
    }));
  };

  const removeFromCart = (
    product: Product,
    removeFull?: boolean,
    variation?: { name: string; price: string }
  ) => {
    const item = `${product.id}${variation ? `_${variation.name}` : ""}`;
    let count: any = getCartItemCount(item);
    if (count - 1 === 0 || removeFull) {
      removeCartItem(item);
      setCartCounts((d: CartCount) => ({
        ...d,
        [item]: 0,
      }));
    } else {
      setCartItemCount(item, count - 1, product);
      setCartCounts((d: CartCount) => ({
        ...d,
        [item]: count - 1,
      }));
    }
  };

  const handleKeywordChange = (key: string) => {
    Object.keys(error)?.length && setError({});
    if (activeCategory !== ALL_CATEGORY) {
      setActiveCategory(ALL_CATEGORY);
      // @ts-ignore
      navRef?.current?.scrollTo?.({
        left: 0,
        behaviour: "smooth",
      });
    }
    if (pageNumber !== 0) setPageNumber(0);
    setKeyword(key);
  };

  const clearCart = (isOrder?: boolean) => {
    if (isOrder) {
      sessionStorage.setItem(CLEAR_CART_ON_NEXT_LOAD, TRUE);
      return;
    }
    const counts = { ...cartCounts };
    Object.keys(counts).forEach((key: string) => {
      counts[key] = 0;
      removeCartItem(key);
    });
    setCartCounts(counts);
  };

  const renderNotFound = () => {
    return (
      <div className="center-x-y h-screen">
        <div className="code">404</div>
        <div
          className="message"
          style={{ padding: 10, fontSize: 18, textAlign: "center" }}
        >
          Business Not Found  
        </div>
      </div>
    );
  };

  const renderToast = (msg: string) => {
    if (!msg) return null;
    return <Toast msg={msg} />;
  };
  // const info = (storeInfo.store_name || "-") + " | Ceroto" || "Ceroto Store";

  if (loading) return <Loader />;

  return notFound ? (
    renderNotFound()
  ) : (
    <div>
      <Header storeInfo={storeInfo} />
      <GridChange
        onThemeChange={(theme: string) => {
          setcurrentTheme(theme);
        }}
      />
      <CategoriesNavBar
        activeCategory={activeCategory}
        categories={categories}
        onCategoryChange={(category: string) => {
          Object.keys(error).length && setError({});
          if (pageNumber !== 0) setPageNumber(0);
          setActiveCategory(category);
        }}
        onKeywordChange={handleKeywordChange}
        keyword={keyword}
        navRef={navRef}
      />
      {products?.length ? (
        <ProductList
          lastProductElementRef={lastProductElementRef}
          storeInfo={storeInfo}
          onAddToCart={addProductToCart}
          onRemoveFromCart={removeFromCart}
          products={products}
          cartCounts={cartCounts}
        />
      ) : (
        !loadingProducts && (
          <p
            className="center-x-y w-100 text-muted py-30"
            style={{ fontSize: 25 }}
          >
            <i
              className="fa fa-meh-o mr-10"
              aria-hidden="true"
              style={{ fontSize: 40 }}
            />
            Oops! No result found
          </p>
        )
      )}
      {loadingProducts && (
        <ProductShimmer
          storeTheme={storeTheme}
          hasProducts={!!products?.length}
        />
      )}
      <Cart
        extraCharges={extraCharges}
        storeInfo={storeInfo}
        cartOptions={cartOptions}
        fromWhatsAppNo={storeInfo.store_whatsapp}
        clearCart={clearCart}
        cartCounts={cartCounts}
        onAddToCart={addProductToCart}
        onRemoveFromCart={removeFromCart}
        setError={setError}
        error={error}
      />
      {renderToast(error["order"])}
      {renderToast(error["cart"])}
      {renderToast(error["mobileNumber"])}
      {renderToast(error["description"])}
      {specialOffer && (
        <InitialPopup
          product={specialOffer}
          storeInfo={storeInfo}
          onAddToCart={addProductToCart}
          onRemoveFromCart={removeFromCart}
          cartCounts={cartCounts}
        />
      )}
    </div>
  );
};

export default ProductDashboard;
