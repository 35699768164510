import * as React from "react";
import { appendCurrency, resolveImgSrc } from "../../utils/product";
import AddItemIcon from "../icons/addItemIcon";
import RemoveItemIcon from "../icons/removeItemIcon";
import { Product } from "../types/product";
import { ProductDetailsCardProps } from "./productDetailsCard_pro-grid";

const SpecialOfferCard: React.FC<ProductDetailsCardProps> = (props) => {
  const { product, onAddToCart, onRemoveFromCart, cartCounts, storeInfo } =
    props;
  const productImageArray = Object.values(product.images);

  const variationData: Product["variationData"] = product.variation_data
    ? JSON.parse(product.variation_data)
    : undefined;

  const [selectedOption, setSelectedOption] = React.useState(
    variationData ? variationData?.variations?.[0] : undefined
  );

  const handleVariationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;
    setSelectedOption(
      variationData.variations?.filter(
        (variation: any) => variation.name === val
      )[0]
    );
  };

  const renderVariations = () => {
    const variations = variationData.variations;
    if (!variations?.length) return null;
    return (
      <div className="center-y flex-col mb-10 px-2">
        <div className="mr-10 t-body2 font-bold">
          {variationData.variation_title}:
        </div>
        <select
          value={selectedOption?.name}
          className="cp form-control-range"
          onChange={handleVariationChange}
          style={{
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "2px 4px",
          }}
        >
          {variations?.map((variation: { price: string; name: string }) => (
            <option
              key={`${variation.name}_${variation.price}`}
              value={variation.name}
            >
              {variation.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const _callFunc = (func: any, product: Product) => {
    func(
      product,
      undefined,
      variationData
        ? {
            name: selectedOption?.name,
            price: selectedOption?.price,
          }
        : undefined
    );
  };

  const handleAddToCart = (product: Product) => {
    _callFunc(onAddToCart, product);
  };

  const handleRemoveFromCart = (product: Product) => {
    _callFunc(onRemoveFromCart, product);
  };

  const count =
    cartCounts[
      `${product.id}${selectedOption ? `_${selectedOption.name}` : ""}`
    ];

  return (
    <div className="special-offer-card mx-auto">
      <div
        className="product-item flex flex-col relative"
        style={{ boxShadow: "none" }}
      >
        {productImageArray.length > 0 ? (
          // <a
          //   href={resolveImgSrc(productImageUrl)}
          //   data-fancybox="gallery"
          //   data-caption={product.name}
          //   data-options={JSON.stringify(FancyBoxOptions)}
          // >
          <img
            src={resolveImgSrc(productImageArray[0].image)}
            alt="Product"
            className="special-offer-product-image"
          />
        ) : (
          // </a>
          <div className="product-img-placeholder center-x-y t-caption text-info">
            Product Image not available
          </div>
        )}
        <div className="py-10">
          <div className="text-center product-name">{product.name}</div>
          {product.description ? (
            <div
              className="the_excerpt_desc t-caption text-secondary m-2 special-offer-description"
              title={product.description}
            >
              {product.description}
            </div>
          ) : null}
        </div>
        <div className="mt-auto">
          {variationData ? (
            <>
              {renderVariations()}
              {storeInfo.price_display ? (
                <div
                  className="text-center font-bold product-name"
                  style={{ marginBottom: 5 }}
                >
                  {selectedOption.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(selectedOption.price || "-")}
                    </span>
                  ) : null}
                  <span>
                    {appendCurrency(
                      selectedOption.sale_price || selectedOption.price || "-"
                    )}
                  </span>
                </div>
              ) : null}
            </>
          ) : storeInfo.price_display ? (
            <div
              className="text-center font-bold product-name"
              style={{ marginBottom: 5 }}
            >
              {product.sale_price ? (
                <span
                  className="t-caption text-secondary"
                  style={{ marginRight: 5, textDecoration: "line-through" }}
                >
                  {appendCurrency(product.price || "-")}
                </span>
              ) : null}
              <span>
                {appendCurrency(product.sale_price || product.price || "-")}
              </span>
            </div>
          ) : null}
          {count ? (
            <div className="center-y justify-center py-10">
              <RemoveItemIcon
                onClick={() => handleRemoveFromCart(product)}
                className="cp"
              />
              <span className="font-bold t-body2 mx-15 no-select">{count}</span>
              <AddItemIcon
                onClick={() => handleAddToCart(product)}
                className="cp"
              />
            </div>
          ) : (
            <button
              className="cart-btn my-5 cart-btn-success cart-btn-add center-y m-auto"
              onClick={() => handleAddToCart(product)}
            >
              <i
                className="fa fa-shopping-cart add-to-cart-icon"
                aria-hidden="true"
              />
              <span className="font-bold" style={{ marginLeft: 5 }}>
                Add to Cart
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferCard;
