import { STORE_THEME } from "app/components/types/product";
import React from "react";
import "app/common/shimmer/shimmer.css";

export interface ProductShimmerCardProps {
  storeTheme: string;
}

const ProductShimmerCard_ProGrid: React.FC<ProductShimmerCardProps> = ({
  storeTheme,
}) => {
  return (
    <div
      className={`flex flex-col br-4 mx-auto ${
        storeTheme === STORE_THEME.PRO_GRID_2
          ? "product-details-pro-grid-2"
          : "product-details"
      }`}
    >
      <div className="product-item">
        <div className="product-img shine" />
        <div className="product-name center-x-y flex-col">
          <div className="line shine" />
          <div className="line shine" />
          <div className="placeholder-button shine" />
        </div>
      </div>
    </div>
  );
};

export default ProductShimmerCard_ProGrid;
