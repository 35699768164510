import { FancyBoxOptions } from "app/utils/fancyboxOptions";
import * as React from "react";
import { appendCurrency, isEmpty, resolveImgSrc } from "../../utils/product";
import AddItemIcon from "../icons/addItemIcon";
import RemoveItemIcon from "../icons/removeItemIcon";
import { CartCount, Product, StoreInfo } from "../types/product";
import StarIcon from "../icons/star.svg";

// Image Preview - https://codepen.io/ongtiffany/pen/BoOeQV

export interface ProductDetailsCardProps {
  storeInfo: StoreInfo;
  product: Product;
  cartCounts: CartCount;
  onAddToCart(
    product: Product,
    itemCount?: number,
    variation?: { name: string; price: string }
  ): void;
  onRemoveFromCart(
    product: Product,
    removeFull?: boolean,
    variation?: { name: string; price: string }
  ): void;
  inPopup?: boolean;
  lastProductElementRef?: React.Ref<any>;
}

const ProductDetailsCard_NEWGRID: React.FC<ProductDetailsCardProps> = (
  props: ProductDetailsCardProps
) => {
  const {
    product,
    onAddToCart,
    onRemoveFromCart,
    cartCounts,
    storeInfo,
    lastProductElementRef,
  } = props;
  const productImageArray = Object.values(product.images);
  const isTodaySpecial = product.today_special;
  const variationData: Product["variationData"] = product.variation_data
    ? JSON.parse(product.variation_data)
    : undefined;

  const [selectedOption, setSelectedOption] = React.useState(
    variationData ? variationData?.variations?.[0] : undefined
  );

  const handleVariationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const val = e.target.value;
    setSelectedOption(
      variationData.variations?.filter(
        (variation: any) => variation.name === val
      )[0]
    );
  };

  const renderVariations = () => {
    const variations = variationData.variations;
    if (!variations?.length) return null;
    return (
      <div className="flex flex-col align-items-center mb-10 px-2">
        <div
          className="mr-10 t-body2 font-bold"
          style={{
            fontSize: "17px",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {variationData.variation_title}
        </div>
        <select
          value={selectedOption?.name}
          className="cp form-control-range"
          onChange={handleVariationChange}
          style={{
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "5px 10px",
            width: "fit-content",
          }}
        >
          {variations?.map((variation: { price: string; name: string }) => (
            <option
              key={`${variation.name}_${variation.price}`}
              value={variation.name}
            >
              {`${variation.name} ( ${appendCurrency(variation.price)} )`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const _callFunc = (func: any, product: Product) => {
    func(
      product,
      undefined,
      variationData
        ? {
            name: selectedOption?.name,
            price: selectedOption?.price,
          }
        : undefined
    );
  };

  const handleAddToCart = (product: Product) => {
    _callFunc(onAddToCart, product);
  };

  const handleRemoveFromCart = (product: Product) => {
    _callFunc(onRemoveFromCart, product);
  };

  const count =
    cartCounts[
      `${product.id}${selectedOption ? `_${selectedOption.name}` : ""}`
    ];

  return (
    <div
      className="col-6 product-details-pro-grid-2"
      style={{ width: "40%" }}
      ref={lastProductElementRef}
    >
      <div className="product-item flex px-2 flex-responsive relative justify-content-md-around justify-content-between  items-center ">
        <div className="flex flex-responsive col-md-5 align-center">
          {productImageArray.length > 0 ? (
            <>
              {productImageArray.map((item: any, index: number) => (
                <a
                  key={index}
                  href={resolveImgSrc(item.image)}
                  data-fancybox={`group${product.id}`}
                  data-caption={product.name}
                  className="flex justify-center"
                  data-options={JSON.stringify(FancyBoxOptions)}
                >
                  <img
                    src={resolveImgSrc(item.image)}
                    alt="Product"
                    className={`img-for-new-grid ${index > 0 ? "d-none" : ""}`}
                    style={{
                      objectFit: "cover",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  />
                  {isTodaySpecial ? (
                    <div className="absolute top-0 left-0 text-center">
                      <img
                        width={30}
                        height={30}
                        src={StarIcon}
                        alt="Today's Special"
                      />
                    </div>
                  ) : null}
                </a>
              ))}
            </>
          ) : (
            <img
              src="not-found.png"
              alt="not-found"
              width="100%"
              height="100%"
              className={`img-for-new-grid`}
              style={{ objectFit: "cover" }}
            />
          )}
          <div className="display-data">
            <span className="text-sm text-secondary text-center">
              {product.category}
            </span>
            <span className="text-center product-name">{product.name}</span>
          </div>
        </div>
        <div className="flex flex-col justify-between col-md-7">
          <div className="hide-data">
            <span className="text-sm text-secondary text-center">
              {product.category}
            </span>
            <span className="text-center product-name">{product.name}</span>
          </div>
          {product.description ? (
            <div
              className="text-sm text-center text-secondary"
              title={product.description}
            >
              {product.description}
            </div>
          ) : null}
          {!isEmpty(variationData) ? (
            <div className="center-y flex-col">
              {renderVariations()}
              {storeInfo.price_display ? (
                <div className="font-600 product-name">
                  {/* {selectedOption?.sale_price ? (
                    <span
                      className="t-caption text-secondary"
                      style={{ marginRight: 5, textDecoration: "line-through" }}
                    >
                      {appendCurrency(selectedOption?.price || "-")}
                    </span>
                  ) : null} */}
                  {selectedOption.price == parseInt(product.price) &&
                  product.sale_price ? (
                    <>
                      <span
                        className="t-caption text-secondary"
                        style={{
                          marginRight: 5,
                          textDecoration: "line-through",
                        }}
                      >
                        {appendCurrency(product.price || "-")}
                      </span>
                      {appendCurrency(product.sale_price)}
                    </>
                  ) : (
                    appendCurrency(selectedOption.price)
                  )}
                </div>
              ) : null}
            </div>
          ) : storeInfo.price_display ? (
            <div className="font-600 product-name text-center">
              {product.sale_price ? (
                <span
                  className="t-caption text-secondary"
                  style={{ marginRight: 5, textDecoration: "line-through" }}
                >
                  {appendCurrency(product.price || "-")}
                </span>
              ) : null}
              {appendCurrency(product.sale_price || product.price || "-")}
            </div>
          ) : null}
          {count ? (
            <div className="center-y justify-center py-10">
              <RemoveItemIcon
                onClick={() => handleRemoveFromCart(product)}
                className="cp"
              />
              <span className="font-bold t-body2 mx-15 no-select">{count}</span>
              <AddItemIcon
                onClick={() => handleAddToCart(product)}
                className="cp"
              />
            </div>
          ) : (
            <button
              className="cart-btn  cart-btn-success cart-btn-add center-y mx-auto"
              onClick={() => handleAddToCart(product)}
            >
              <i
                className="fa fa-shopping-cart add-to-cart-icon"
                aria-hidden="true"
              />
              <span className="font-bold" style={{ marginLeft: 5 }}>
                Add to Cart
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsCard_NEWGRID;
