import { BUSINESS_NAME } from "app/services/apiService";
import {
  ALL_CATEGORY,
  TODAY_SPECIAL_CATEGORY,
} from "../components/product/productDashboard";
import { Product, STORE_CURRENCY_POSITION } from "../components/types/product";

export const isEmpty = (data: any) => {
  if (!data) return true;
  if (Array.isArray(data)) return !data?.length;
  else if (typeof data === "object") return !Object.keys(data)?.length;
  return false;
};
export interface OrderMetadata {
  order_id: string;
  fromWhatsAppNo: string;
  deliveryOption: string;
  selectedPaymentOption: string;
  itemNotes?: any;
  totalAmount: string;
  userName: string;
  currency: string;
  mobileNumber: string;
  nameAddress: string;
  userOrderNote?: any;
  couponCodeDiscount?: number;
  charges?: any;
  discountPercentage?: string;
}

export const filterProducts = (
  products: Product[],
  productCategory: Product["category"],
  keyword: string
) => {
  return products.filter((product: Product) => {
    if (!keyword)
      return (
        productCategory === ALL_CATEGORY ||
        (productCategory === TODAY_SPECIAL_CATEGORY &&
          Boolean(product.today_special)) ||
        product.category === productCategory
      );

    //else
    return product?.name?.toLowerCase()?.includes(keyword?.toLowerCase());
  });
};

export const appendCurrency = (price?: string | number) => {
  if (!price) return price;
  const CURRENCY = window["$currency"];
  const CURRENCY_POSITION = window["$currency_position"];

  return `${CURRENCY_POSITION === STORE_CURRENCY_POSITION.before
    ? `${CURRENCY} ${price}`
    : `${price} ${CURRENCY}`
    }`;
};
export const clearLastOrderDataFully = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (
      key?.includes(`${BUSINESS_NAME}~`) || // --> Product Data
      key?.includes(`${BUSINESS_NAME}#`) // --> ORDER_METADATA
    ) {
      sessionStorage.removeItem(key);
    }
  });
};
export const resolveImgSrc = (relativePath: string) => {
  return `/uploads/${relativePath}`;
};
export interface StoreInfo {
  address: string;
  logo_display: number;
  price_display: number;
  quantity_display: number;
  title_display: number;
  store_lang: string;
  store_about: string;
  store_bgcolor: string;
  store_color: string;
  store_logo: string;
  store_name: string;
  store_whatsapp: string;
  store_theme: string;
  store_currency: string;
  store_currency_position?: string;
}