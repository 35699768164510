import HorizontalLoader from "app/common/horizontalLoader";
import {
  getCartItemProduct,
  getCartItemProductWithTimeBound,
  getCookie,
  setCookie,
  updateCartProductItem,
} from "app/utils/browser";
import { appendCurrency } from "app/utils/product";
import * as React from "react";
import {
  applyCouponCode,
  BUSINESS_NAME,
  DISCOUNT_TYPE,
  getCartProducts,
  getOrderId,
  paymentgateway,
  setOrderMetadataInSesstionStorage,
} from "../../../services/apiService";
import {
  API_DATA,
  CartCount,
  CartOptions,
  Product,
  StoreInfo,
} from "../../types/product";
import CartProductItem from "./cartProductItem";

interface Props {
  storeInfo: StoreInfo;
  cartOptions: CartOptions;
  fromWhatsAppNo: string;
  cartCounts: CartCount;
  extraCharges: API_DATA["extra_charges"];
  error: object;
  onAddToCart(product: Product, count?: number): void;
  onRemoveFromCart(product: Product, removeFull?: boolean): void;
  clearCart(isOrder?: boolean): void;
  setError(error: object): void;
}

const Cart: React.FC<Props> = (props: Props) => {
  const {
    storeInfo,
    cartOptions,
    onRemoveFromCart,
    onAddToCart,
    cartCounts,
    clearCart,
    extraCharges,
    fromWhatsAppNo,
    setError,
    error,
  } = props;

  const [loadingCartProducts, setLoadingCartProducts] =
    React.useState<boolean>(false);

  const [cartProducts, setCartProducts] = React.useState<Product[]>([]);

  const [mobileNumber, setMobileNumber] = React.useState<string>(
    getCookie("mobileNumber") || ""
  );
  const [nameAddress, setNameAddress] = React.useState<string>(
    getCookie("nameAddress") || ""
  );
  const [orderNote, setOrderNote] = React.useState<string>(
    getCookie("orderNote") || ""
  );
  const [deliveryOption, setDeliveryOption] = React.useState<string>(
    Object.values(cartOptions.delivery_options || {})[0]
  );
  const [itemNotes, setItemNotes] = React.useState<Map<string, string>>(
    {} as Map<string, string>
  );

  const [couponCode, setCouponCode] = React.useState("");
  const [couponCodeMessage, setCouponCodeMessage] =
    React.useState<any>(undefined);
  const [couponCodeDiscount, setCouponCodeDiscount] =
    React.useState<any>(undefined);
  const [applyingCouponCode, setApplyingCouponCode] =
    React.useState<boolean>(false);

  let totalAmount = 0;
  let totalCount = 0;
  Object.keys(sessionStorage).forEach((key: string) => {
    if (key?.includes(`${BUSINESS_NAME}~`)) {
      totalCount += 1;
    }
  });
  React.useEffect(() => {}, [totalCount]);
  cartProducts.forEach((product: Product) => {
    if (product.variation_data) {
      // eslint-disable-next-line
      JSON.parse(product.variation_data).variations.map((item: any) => {
        if (product.id.split("_")[1] === item.name) {
          if (product.sale_price) {
            if (product.price.split(".")[0] === item.price) {
              totalAmount +=
                cartCounts[product.id] * Number(product.sale_price);
            } else {
              totalAmount += cartCounts[product.id] * Number(item.price);
            }
          } else {
            totalAmount += cartCounts[product.id] * Number(item.price);
          }
        }
      });
    } else {
      // totalCount += cartCounts[product.id];
      totalAmount += Number(
        (
          cartCounts[product.id] * +(product.sale_price || product.price)
        ).toFixed(3)
      );
    }
  });
  React.useEffect(() => {
    if (couponCode !== "") addCouponCode();
    // eslint-disable-next-line
  }, [totalAmount]);
  const minAmountForCart = extraCharges
    ? Number(extraCharges?.minimum_total)
    : 0;
  const charges = extraCharges ? Number(extraCharges?.charges) : 0;
  const toBeApplied = extraCharges?.apply_for
    ? Object.values(extraCharges?.apply_for).includes(deliveryOption)
    : false;
  const chargeApplicable = toBeApplied && totalAmount < minAmountForCart;
  const hasSomethingInCart = cartProducts?.length;

  if (!hasSomethingInCart) {
    couponCodeDiscount && setCouponCodeDiscount(undefined);
    couponCodeMessage && setCouponCodeMessage(undefined);
  }
  let selectedPaymentOption = "kmat";
  const setOrderMetadataInSessionStorage = (order_id: string) => {
    setOrderMetadataInSesstionStorage({
      order_id,
      fromWhatsAppNo,
      deliveryOption,
      selectedPaymentOption,
      currency: window["$currency"],
      itemNotes,
      totalAmount: (
        totalAmount -
        getCouponCodeDiscount() +
        (chargeApplicable && hasSomethingInCart ? charges : 0)
      ).toFixed(3),
      // userName,
      mobileNumber,
      nameAddress,
      orderNote,
      couponCodeDiscount: getCouponCodeDiscount(),
      charges: chargeApplicable && hasSomethingInCart ? charges : undefined,
      discountPercentage:
        couponCodeDiscount?.type === DISCOUNT_TYPE.Percentage
          ? couponCodeDiscount?.discount
          : "",
    });
  };
  const getCouponCodeDiscount = () => {
    if (
      !couponCodeDiscount ||
      !couponCodeDiscount?.type ||
      !couponCodeDiscount?.discount
    )
      return 0;
    let actualDiscount = 0;
    if (couponCodeDiscount?.type === DISCOUNT_TYPE.Fixed) {
      actualDiscount =
        totalAmount > Number(couponCodeDiscount?.discount)
          ? Number(couponCodeDiscount?.discount)
          : totalAmount;
    } else if (couponCodeDiscount?.type === DISCOUNT_TYPE.Percentage) {
      const d = (totalAmount * Number(couponCodeDiscount?.discount)) / 100;
      actualDiscount =
        totalAmount - d <= 0
          ? Number(totalAmount.toFixed(3))
          : Number(d.toFixed(3));
    }
    return Number(actualDiscount.toFixed(3));
  };

  const submitOrder = (location?: string) => {
    sessionStorage.setItem("prev_store", BUSINESS_NAME);
    const productsMapByIdToName = {};
    cartProducts.forEach((product: Product) => {
      productsMapByIdToName[product.id] = `${product.name}${
        product.variation ? `(${product.variation})` : ""
      }`;
    });
    const items = cartProducts.map((product: Product) => {
      let price = 0;
      if (product.variation_data) {
        JSON.parse(product.variation_data).variations.map((item: any) => {
          if (item.name === product.variation) {
            if (product.price.split(".")[0] === item.price) {
              if (product.sale_price) {
                price = parseFloat(product.sale_price);
              } else {
                price = parseFloat(item.price);
              }
            } else {
              price = parseFloat(item.price);
            }
          }
        });
      } else {
        price = parseFloat(product.sale_price || product.price);
      }
      const amount = cartCounts[product.id] * price;
      return {
        name: `${product.name} ${
          product.variation ? `(${product.variation})` : ""
        }`,
        qty: cartCounts[product.id].toString(),
        price: price,
        amt: Number(amount.toFixed(3)),
        note: itemNotes[product.id] || "",
      };
    });
    getOrderId({
      location,
      delivery_options: deliveryOption,
      mobile: mobileNumber,
      name_address: nameAddress,
      order_note: orderNote,
      subtotal: totalAmount,
      discount: getCouponCodeDiscount(),
      extra_charge: chargeApplicable ? Number(charges) : 0,
      discount_code: couponCode,
      items,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else throw Error;
      })
      .then((res) => {
        const orderId = res.order_id;
        if (orderId) {
          paymentgateway({
            orderId: res.order_id,
            totalAmount: totalAmount - getCouponCodeDiscount(),
          })
            .then((res: any) => {
              setOrderMetadataInSessionStorage(orderId);
              if (res.status === "success") {
                window.location.href = res.paymentURL;
              }
            })
            .then((res) => {
              console.log(res);
            });
        }
        // replaceToWhatsAppAPI(
        //   res.order_id || "",
        //   fromWhatsAppNo,
        //   productsMapByIdToName,
        //   cartCounts,
        //   itemNotes,
        //   deliveryOption,
        //   (
        //     totalAmount -
        //     getCouponCodeDiscount() +
        //     (chargeApplicable && hasSomethingInCart ? charges : 0)
        //   ).toFixed(3),
        //   mobileNumber,
        //   nameAddress,
        //   getCouponCodeDiscount(),
        //   chargeApplicable && hasSomethingInCart ? charges : undefined,
        //   couponCodeDiscount?.type === DISCOUNT_TYPE.Percentage
        //     ? couponCodeDiscount?.discount
        //     : ""
        // );
        setCookie("nameAddress", nameAddress);
        setCookie("mobileNumber", mobileNumber);
        setCookie("orderNote", orderNote);
        clearCart(true);
      })
      .catch((e) => console.log(e));
  };

  const handleOrderSubmit = () => {
    if (!totalCount) {
      setError({ cart: "Your cart is empty" });
      return;
    }
    if (!mobileNumber) {
      setError({ mobileNumber: "Please Enter valid mobile number!" });
      return;
    }
    if (!nameAddress) {
      setError({ description: "Please Enter address!" });
      return;
    }
    fetch("https://www.cloudflare.com/cdn-cgi/trace", { method: "GET" })
      .then((res) => res.text())
      .then((res) => submitOrder(res))
      .catch(() => submitOrder());
  };

  React.useEffect(() => {
    const cartProds: Product[] = [];
    Object.keys(sessionStorage)?.forEach((key) => {
      const splits = key.split("~");
      const business = splits[0];
      if (business === BUSINESS_NAME && splits.length > 1) {
        const product = getCartItemProduct(splits[1]);
        const variation = splits[1]?.split("_")?.[1];
        if (product) {
          cartProds.push({
            ...product,
            variation,
            id: splits[1],
          });
        }
      }
    });
    setCartProducts(cartProds);
  }, [cartCounts]);

  const loadCartProducts = () => {
    const cartProds: Product[] = [];
    const productsIds: string[] = [];
    Object.keys(sessionStorage)?.forEach((key) => {
      const splits = key.split("~");
      const business = splits[0];
      if (business === BUSINESS_NAME && splits.length > 1) {
        const product = getCartItemProductWithTimeBound(splits[1]);
        const variation = splits[1]?.split("_")?.[1];
        if (product) {
          cartProds.push({
            ...product,
            variation,
            id: splits[1],
          });
        } else {
          productsIds.push(splits[1]?.split("_")[0]);
        }
      }
    });

    cartProds.length && setCartProducts(cartProds);

    // fetch products here from productsIds
    if (productsIds.length) {
      setLoadingCartProducts(true);
      getCartProducts(productsIds)
        .then((res) => {
          if (res.status === 200) return res.json();
          else throw Error;
        })
        .then((res) => {
          const prods = Object.values(res?.products || {}) as Product[];
          if (prods) {
            prods.forEach((product: Product) => {
              const variationData: Product["variationData"] =
                product.variation_data
                  ? JSON.parse(product.variation_data)
                  : undefined;
              if (variationData) {
                const variations = variationData.variations;
                if (variations?.length) {
                  variations.forEach((variation: any) => {
                    const key = `${product.id}_${variation.name}`;
                    if (cartCounts[key]) {
                      updateCartProductItem(key, product);
                      cartProds.push({
                        id: `${product.id}_${variation.name}`,
                        name: product.name,
                        variation,
                        price: variation.price,
                        images: product.images,
                        product_description_e: product.product_description_e,
                      } as Product);
                    }
                  });
                } else if (cartCounts[product.id]) {
                  updateCartProductItem(product.id, product);
                  cartProds.push(product);
                }
              } else if (cartCounts[product.id]) {
                updateCartProductItem(product.id, product);
                cartProds.push(product);
              }
            });
          }
          setCartProducts(cartProds);
          setLoadingCartProducts(false);
        })
        .catch(() => {
          setLoadingCartProducts(false);
        });
    }
  };

  const renderFloatButton = () => (
    <button
      type="button"
      className="btn float-btn d-flex center-x-y z-2"
      data-toggle="modal"
      data-target="#cart"
      onClick={loadCartProducts}
    >
      <div className="relative">
        <div className="float-count center-x-y">{totalCount}</div>
        <i className="fa fa-shopping-cart cart-icon" aria-hidden="true" />
      </div>
    </button>
  );

  const renderModalHeader = () => (
    <div className="modal-header">
      <div>
        <h5 className="modal-title" id="cartTitle">
          Checkout
        </h5>
        <div className="text-secondary t-body1 font-400">
          {storeInfo.title_display ? storeInfo.store_name : ""}
        </div>
        <div className="text-secondary text-danger t-caption font-400 text-critical">
          {cartOptions.any_condition}
        </div>
      </div>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        style={{ fontSize: 20 }}
      >
        <span aria-hidden="true">x</span>
      </button>
    </div>
  );

  const renderModalFooter = () => (
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-outline-danger"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
        id="worder"
        type="button"
        className={`cart-btn cart-btn-success center-y ${
          hasSomethingInCart ? "" : "pointer-events-none opacity-50"
        }`}
        onClick={handleOrderSubmit}
      >
        <i
          className="fa fa-whatsapp"
          style={{ fontSize: 30 }}
          aria-hidden="true"
        />
        <span style={{ marginLeft: 5 }}>Order on WhatsApp</span>
      </button>
    </div>
  );

  const addCouponCode = () => {
    if (!couponCode) {
      setCouponCodeMessage({ msg: "Please Enter coupon code", error: 1 });
    } else {
      setApplyingCouponCode(true);
      applyCouponCode(couponCode, totalAmount)
        .then((res) => {
          // if (res.status === 200) {
          //   return res.json();
          // } else {
          //   throw Error;
          // }
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            setCouponCodeDiscount(undefined);
          }
          if (!res.type || !res.discount || res.error) {
            setCouponCodeMessage({
              msg: res.error || "Coupon Code is invalid",
              error: 1,
            });
            setApplyingCouponCode(false);
            return;
          }
          const { type, discount } = res;
          if (type === DISCOUNT_TYPE.Fixed) {
            const actualDiscount =
              totalAmount > Number(discount) ? Number(discount) : totalAmount;
            setCouponCodeMessage({
              msg: `Congratulations, you have got ${appendCurrency(
                actualDiscount.toFixed(3)
              )} discount`,
            });
            setCouponCodeDiscount(res);
          } else if (type === DISCOUNT_TYPE.Percentage) {
            setCouponCodeMessage({
              msg: `Congratulations, you have got ${discount}% discount`,
            });
            setCouponCodeDiscount(res);
          } else {
            setCouponCodeMessage({ msg: "Coupon code is invalid", error: 1 });
          }
          setApplyingCouponCode(false);
        })
        .catch(() => {
          setCouponCodeMessage({
            msg: "Coupon Code is invalid",
            error: 1,
          });
          setApplyingCouponCode(false);
        });
    }
  };

  const removeError =
    (func: any) =>
    (...args: any[]) => {
      Object.keys(error)?.length && setError({});
      func(...args);
    };

  const renderModalBody = () => {
    return (
      <div className="modal-body">
        {chargeApplicable && hasSomethingInCart ? (
          <div className="text-right t-caption text-muted font-italic mb-1">
            *Minimum order amount to avoid extra charges:{" "}
            {appendCurrency(minAmountForCart.toFixed(3))}
          </div>
        ) : null}
        {hasSomethingInCart ? (
          cartProducts.map((product: Product) => (
            <CartProductItem
              key={`${product.id}_${product.sale_price || product.price}`}
              product={product}
              count={cartCounts[product.id]}
              onAddProduct={removeError(onAddToCart)}
              onRemoveFromCart={removeError(onRemoveFromCart)}
              note={itemNotes[product.id]}
              onNoteChange={(note: string) =>
                setItemNotes({
                  ...itemNotes,
                  [product.id]: note,
                })
              }
            />
          ))
        ) : !loadingCartProducts ? (
          <div className="text-secondary font-500 text-center">
            Cart is Empty! <br />
            Choose your products
          </div>
        ) : null}
        {loadingCartProducts && (
          <div className="my-2">
            <HorizontalLoader />
          </div>
        )}

        <div className="flex promo-code-total-section">
          {hasSomethingInCart ? (
            <div className="mx-auto promo-code-section">
              <div className="center-y">
                <input
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  name="promoCode"
                  className="form-control"
                  style={{
                    boxShadow: "none",
                    fontSize: 12,
                    padding: "5px 10px",
                  }}
                  id="promoCode"
                  placeholder="Have coupon code? Enter Here.."
                  spellCheck="false"
                  maxLength={30}
                  disabled={couponCodeDiscount}
                />
                {couponCodeDiscount ? (
                  <i
                    className="fa fa-times-circle cp ml-2"
                    style={{ color: "red" }}
                    onClick={() => {
                      setCouponCodeDiscount(undefined);
                      setCouponCodeMessage(undefined);
                      setCouponCode("");
                    }}
                  />
                ) : (
                  <button
                    className="btn btn-success ml-2 t-caption"
                    onClick={addCouponCode}
                    disabled={applyingCouponCode}
                  >
                    Apply
                  </button>
                )}
              </div>
              {couponCodeMessage?.msg || applyingCouponCode ? (
                <div
                  className="t-caption text-left"
                  style={{
                    color:
                      couponCodeMessage?.error && !applyingCouponCode
                        ? "red"
                        : "green",
                  }}
                >
                  {(applyingCouponCode ? "Applying...." : "") ||
                    couponCodeMessage?.msg}
                </div>
              ) : null}
            </div>
          ) : null}
          <div
            className={`text-right ml-auto ${
              hasSomethingInCart ? "" : "hidden"
            }`}
          >
            {couponCodeDiscount || (chargeApplicable && hasSomethingInCart) ? (
              <div className="font-600 t-body1">
                Subtotal: {appendCurrency(totalAmount.toFixed(3))}
              </div>
            ) : null}
            {couponCodeDiscount ? (
              <div className="t-body2" style={{ color: "green" }}>
                {" "}
                - Coupon discount{" "}
                {`${
                  couponCodeDiscount?.type === DISCOUNT_TYPE.Percentage
                    ? `(${couponCodeDiscount?.discount}%)`
                    : ""
                }`}
                : {appendCurrency(getCouponCodeDiscount().toFixed(3))}
              </div>
            ) : null}
            {chargeApplicable && hasSomethingInCart ? (
              <div className="t-body2 text-info">
                {" "}
                + Extra Charge: {appendCurrency(Number(charges).toFixed(3))}
              </div>
            ) : null}
            <div className="font-bold text-xl">
              Total:{" "}
              {appendCurrency(
                (
                  totalAmount -
                  getCouponCodeDiscount() +
                  (hasSomethingInCart && chargeApplicable ? charges : 0)
                ).toFixed(3)
              )}
            </div>
            <div className="center-y my-15 promo-code-option">
              <button
                type="button"
                className="ml-auto btn btn-outline-danger mb-10 t-caption px-2 py-1"
                onClick={() => clearCart()}
                style={{ boxShadow: "none" }}
              >
                Clear cart
              </button>
            </div>
          </div>
        </div>

        <div
          className={`switch-field py-10 ${hasSomethingInCart ? "" : "hidden"} justify-content-center`}
        >
          {Object.values(cartOptions?.delivery_options || {}).map(
            (option: string) => (
              <div key={`delivery_options_${option}`} className="mr-2">
                <input
                  type="radio"
                  id={option}
                  name={option}
                  value={option}
                  data-value={option}
                  checked={deliveryOption === option}
                  onChange={(e) => setDeliveryOption(e.target.value)}
                />
                <label htmlFor={option}>
                  <span>{option}</span>
                </label>
              </div>
            )
          )}
        </div>

        <div className={`form-group ${hasSomethingInCart ? "" : "hidden"}`}>
          <label htmlFor="mobile" className="font-600">
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            style={{ boxShadow: "none" }}
            placeholder="Enter Your Mobile Number"
            onChange={(e) => {
              setMobileNumber(e.target.value);
              if (error["mobileNumber"])
                setError({ ...error, mobileNumber: undefined });
            }}
            value={mobileNumber}
          />
          {error["mobileNumber"] && (
            <span style={{ color: "var(--danger)" }}>
              Enter valid phone number
            </span>
          )}
        </div>
        <div className={`form-group ${hasSomethingInCart ? "" : "hidden"}`}>
          <label htmlFor="address" className="font-600">
            Name/Address
          </label>
          <textarea
            value={nameAddress}
            onChange={(e) => setNameAddress(e.target.value)}
            name="address"
            className="form-control"
            style={{ boxShadow: "none" }}
            id="address"
            placeholder="Enter Name/Address"
            rows={5}
            spellCheck="false"
          />
        </div>
        {cartOptions.order_note ? (
          <div className={`form-group ${hasSomethingInCart ? "" : "hidden"}`}>
            <label htmlFor="address" className="font-600">
              Order Note
            </label>
            <textarea
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              name="order_note"
              className="form-control"
              style={{ boxShadow: "none" }}
              id="order_note"
              placeholder={cartOptions.order_note_placeholder}
              rows={5}
              spellCheck="false"
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {renderFloatButton()}
      <div
        className="modal fade"
        id="cart"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cartTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            {renderModalHeader()}
            {renderModalBody()}
            {renderModalFooter()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
