import * as React from "react";
import "./loader.css";

// bootstrap loader
const Loader: React.FC = () => {
  return (
    <div className="d-flex justify-content-center">
      {/*bootstrap loader*/}
      {/*<div*/}
      {/*  className="spinner-border"*/}
      {/*  role="status"*/}
      {/*  style={{*/}
      {/*    color: "#0e739e",*/}
      {/*    width: "3rem",*/}
      {/*    height: "3rem",*/}
      {/*    margin: "15%",*/}
      {/*  }}*/}
      {/*>*/}
      <div className="lds-roller" style={{ margin: "23%" }}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
export default Loader;
