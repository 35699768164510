import { Product } from "app/components/types/product";
import { BUSINESS_NAME } from "app/services/apiService";

export const setCookie = (name: string, value: string, days: number = 365) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const CUT_OFF_MINS = 5;

export const getCartItemProduct = (key: string) => {
  const data = JSON.parse(
    sessionStorage.getItem(`${BUSINESS_NAME}~${key}`) || "{}"
  );
  return data?.product;
};

export const getCartItemProductWithTimeBound = (key: string) => {
  const data = JSON.parse(
    sessionStorage.getItem(`${BUSINESS_NAME}~${key}`) || "{}"
  );
  let product = data?.product;
  const modifiedTime = data?.modifiedTime;
  if (product && modifiedTime) {
    const currentDate = new Date();
    const modifiedDate = new Date(modifiedTime);
    const diff = Math.floor(
      (currentDate.getTime() - modifiedDate.getTime()) / (1000 * 60)
    );
    // diff is greater then CUT_OFF_MINS then fetch fresh product
    if (diff > CUT_OFF_MINS) product = undefined;
  }
  return product;
};

export const updateCartProductItem = (key: string, product: Product) => {
  const data = JSON.parse(
    sessionStorage.getItem(`${BUSINESS_NAME}~${key}`) || "{}"
  );
  sessionStorage.setItem(
    `${BUSINESS_NAME}~${key}`,
    JSON.stringify({
      count: data?.count || 1,
      product,
      modifiedTime: Date.now(),
    })
  );
};

export const removeCartItem = (key: string) => {
  sessionStorage.removeItem(`${BUSINESS_NAME}~${key}`);
};

export const getCartItemCount = (key: string) => {
  return Number(
    JSON.parse(sessionStorage.getItem(`${BUSINESS_NAME}~${key}`) || "{}")
      ?.count || 0
  );
};

export const setCartItemCount = (
  key: string,
  count: number,
  product: Product
) => {
  sessionStorage.setItem(
    `${BUSINESS_NAME}~${key}`,
    JSON.stringify({ count, product, modifiedTime: Date.now() })
  );
};

export const getCookie = (name: string) => {
  const cookies = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookies ? cookies.pop() : "";
};
