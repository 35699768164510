import { TODAY_SPECIAL_CATEGORY } from "app/components/product/productDashboard";
import StarIcon from "../../icons/star.svg";
import { ChangeEvent } from "react";
import * as React from "react";
interface Props {
  activeCategory: string;
  categories: string[];
  keyword: string;
  onCategoryChange(categoryId: string): void;
  onKeywordChange(keyword: string): void;
  navRef?: React.Ref<any>;
}

// Animation - https://www.sitepoint.com/building-a-filtering-component-with-css-animations-jquery/

const CategoriesNavBar: React.FC<Props> = (props: Props) => {
  const {
    categories,
    onCategoryChange,
    onKeywordChange,
    keyword,
    activeCategory,
    navRef,
  } = props;

  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onKeywordChange(e.target.value);
  };

  return (
    <div
      className="container mt-20 sticky top-0"
      style={{ backgroundColor: "#FFF", zIndex: 2 }}
    >
      <div id="search" className="center-y">
        <label
          htmlFor="search-input"
          style={{ marginRight: -20, zIndex: 1, marginBottom: 0 }}
        >
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ color: "#28bcad" }}
          />
          <span className="sr-only">Search icons</span>
        </label>
        <input
          style={{ paddingLeft: 30 }}
          id="search-input"
          onChange={handleKeywordChange}
          // className="form-control input-lg"
          className="form-control form-control-sm w-75"
          placeholder="Search..."
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
          tabIndex={1}
          value={keyword}
        />
        {keyword && (
          <div
            style={{ marginLeft: -15 }}
            id="search-clear"
            className="cp fa fa-times-circle hide text-secondary"
            aria-hidden="true"
            onClick={() => onKeywordChange("")}
          >
            <span className="sr-only">Clear search</span>
          </div>
        )}
        {/*<a*/}
        {/*  id="search-clear"*/}
        {/*  href="#"*/}
        {/*  className="fa fa-times-circle hide"*/}
        {/*  aria-hidden="true"*/}
        {/*  onClick={() => onKeywordChange("")}*/}
        {/*>*/}
        {/*  <span className="sr-only">Clear search</span>*/}
        {/*</a>*/}
      </div>
      <div
        className="row justify-content-center"
        style={{ boxShadow: "rgba(255, 255, 255, 0.5) 0px 12px 10px 3px" }}
      >
        <ul
          className="nav nav-tabs mt-30 mx-auto"
          ref={navRef}
          style={{ width: "inherit" }}
        >
          {categories.map((category: string) => (
            <li
              className="nav-item"
              key={category}
              onClick={() => onCategoryChange(category)}
            > 
              <span
                className={`${
                  activeCategory === category ? "nav-active" : ""
                } nav-link`}
              >
                {category === TODAY_SPECIAL_CATEGORY ? (
                  <div className="center-y">
                    <img
                      src={StarIcon}
                      alt="star icon"
                      width={20}
                      height={20}
                      className="zoom-in-out-animation"
                    />
                    {category}
                  </div>
                ) : (
                  category
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoriesNavBar;
