import * as React from "react";
import "./horizontalLoader.css";

const HorizontalLoader: React.FC = () => {
  return (
    <div className="spinner">
      <div className="bounce1 mr-10"></div>
      <div className="bounce2 mr-10"></div>
      <div className="bounce3"></div>
    </div>
  );
};
export default HorizontalLoader;
