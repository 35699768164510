export const STORE_THEME = {
  DEFAULT: "default",
  PRO_LIST: "pro-list",
  PRO_GRID: "pro-grid",
  PRO_GRID_2: "pro-grid-2",
  PRO_NEWGRID: "new-grid"
};

export const STORE_CURRENCY_POSITION = {
  before: "before",
  after: "after",
};

export interface Product {
  id: string;
  name: string;
  description: string;
  category?: string;
  price: string;
  sale_price?: string;
  product_description_e: string;
  images: object;
  product_photos?: string[];
  packing?: string;
  position?: number;
  sku?: string;
  variation_data?: string;
  today_special?: number;
  special_offer?: number;

  // parsed variation_data
  variationData: {
    variation_title?: string;
    variations?: any[];
  };

  // added data
  addedVariations: {
    variationName: string;
    count: number;
  };

  // ui-use
  variation?: string;
}

export type CartCount = Map<string, string>;

export interface StoreInfo {
  logo_display: number;
  price_display: number;
  quantity_display: number;
  title_display: number;
  store_lang: string;
  store_about: string;
  store_bgcolor: string;
  store_color: string;
  store_logo: string;
  store_name: string;
  store_whatsapp: string;
  store_theme: string;
  store_currency: string;
  store_currency_position?: string;
}

export interface CartOptions {
  any_condition?: string;
  delivery_options: string[];
  order_note: number;
  order_note_placeholder: string;
}

export interface Pagination {
  cur_page: number;
  per_page: number;
  total_pro: number;
}

export interface API_DATA {
  cart_options: CartOptions;
  store_info: StoreInfo;
  categories: Object;
  products: Object;
  meta: Object;
  special_offer?: Product;
  extra_charges: {
    apply_for?: Object;
    charges: number;
    minimum_total: number;
  };
  pagination: Pagination;
  today_special?: number;
}
