import ProductShimmerCard_Default from "app/common/shimmer/productShimmerCard_default";
import ProductShimmerCard_ProGrid from "app/common/shimmer/productShimmerCard_pro-grid";
import ProductShimmerCard_ProList from "app/common/shimmer/productShimmerCard_pro-list";
import { STORE_THEME } from "app/components/types/product";
import React from "react";

const ProductShimmer: React.FC<{
  storeTheme: string | undefined;
  hasProducts: boolean;
}> = ({ storeTheme, hasProducts }) => {
  let ShimmerCard: any;
  let NO_OF_SHIMMER_CARD = 8;
  switch (storeTheme) {
    case STORE_THEME.DEFAULT:
      ShimmerCard = ProductShimmerCard_Default;
      break;
    case STORE_THEME.PRO_GRID:
    case STORE_THEME.PRO_GRID_2:
      ShimmerCard = ProductShimmerCard_ProGrid;
      break;
    case STORE_THEME.PRO_LIST:
      NO_OF_SHIMMER_CARD = 9;
      ShimmerCard = ProductShimmerCard_ProList;
      break;
    case STORE_THEME.PRO_NEWGRID:
      NO_OF_SHIMMER_CARD = 9;
      ShimmerCard = ProductShimmerCard_ProList;
      break;
    default:
      ShimmerCard = ProductShimmerCard_Default;
  }
  if (!ShimmerCard) return null;

  return (
    <div
      className={`container ${
        storeTheme === STORE_THEME.DEFAULT
          ? ""
          : "flex flex-wrap justify-around"
      } ${hasProducts ? "" : "pt-30"}`}
    >
      {Array(NO_OF_SHIMMER_CARD)
        .fill(undefined)
        .map((_: any) => (
          <ShimmerCard storeTheme={storeTheme} key={Math.random()} />
        ))}
    </div>
  );
};

export default ProductShimmer;
